<template>
  <ui-button
    v-if="show"
    v-bind="$attrs"
    apple-pay
    @click.prevent="$emit('submit', () => submitPay())"
  />
</template>

<script>
/* eslint-disable no-alert */

import api from '@/api';
import UiButton from '@/components/UiButton.vue';

export default {

  components: {
    UiButton,
  },

  props: {
    listPayment: {
      type: Array,
      required: true,
    },
    feeChecked: {
      type: Boolean,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    feeAmount: {
      type: Number,
      required: true,
    },
    groupId: {
      type: String,
    },
    workplacePayoutId: {
      type: String,
      required: true,
    },
    backUrl: {
      type: Object,
      required: true,
    },
    totalAmount: {
      type: String,
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    show: false,
    applePaySession: null,
  }),

  mounted() {
    if (
      window.ApplePaySession
      && window.ApplePaySession.canMakePaymentsWithActiveCard(this.merchantId)
    ) {
      this.show = true;
    }
  },

  methods: {
    redirect({ status, paymentId }) {
      window.location.href = this.$router.resolve({
        path: `${this.backUrl.locationId}/${status}`,
        query: {
          ...this.backUrl.query,
          checkoutPaymentId: paymentId,
          rate: this.backUrl.rate,
          isApplyCheckoutPayment: true,
          amount: this.totalAmount,
        },
      }).href;
    },
    submitPay() {
      const applePaySession = new window.ApplePaySession(6, {
        countryCode: 'US',
        currencyCode: this.currency.toUpperCase(),
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'],
        total: {
          label: `Tip for ${this.name}`,
          amount: +this.totalAmount,
        },
      });

      applePaySession.begin();

      applePaySession.onvalidatemerchant = async (event) => {
        const validationUrl = event.validationURL;

        try {
          const { data } = await api.validateCheckoutAppleSession({ validationUrl });

          applePaySession.completeMerchantValidation(data);
        } catch (e) {
          alert(e);
        }
      };

      applePaySession.onpaymentauthorized = async (event) => {
        const applePaymentToken = event.payment.token;

        try {
          const { data } = await api.createCheckoutApplePayPayment({
            listPayment: this.listPayment,
            feeChecked: this.feeChecked,
            currency: this.currency,
            amount: +this.totalAmount,
            feeAmount: this.feeAmount,
            workplacePayoutId: this.workplacePayoutId,
            groupId: this.groupId,
            customerCardToken: applePaymentToken,
          });

          if (data) {
            applePaySession.completePayment({
              status: window.ApplePaySession.STATUS_SUCCESS,
            });

            this.redirect({
              status: 'success',
              paymentId: data?.id,
            });
          } else {
            applePaySession.completePayment({
              status: window.ApplePaySession.STATUS_FAILURE,
            });

            this.redirect({ status: 'error' });
          }
        } catch (e) {
          alert(e);
        }
      };
    },
  },
};
</script>
