<template>
  <form method="POST" action="https://merchant.com/charge-card" @submit="onSubmit">
    <div class="payment-form">
      <div
        v-if="loading.init || loading.fee"
        class="payment-form__loader"
      >
        <ui-spinner size="14px" />
      </div>
      <div
        class="payment-form__card-data"
        :class="{
          'payment-form_hidden': loading.init,
        }"
      >
        <label
          for="payment-form__card-number"
          class="payment-form__label"
        >
          Card number
        </label>
        <div class="payment-form__card-number">
          <div class="card-number-frame"></div>
        </div>
        <span
          v-if="errors['card-number']"
          class="payment-form__error-message"
        >
          Please enter a valid card number
        </span>

        <div class="payment-form__container">
          <div class="payment-form__date-container">
            <label
              for="payment-form__expiry-date"
              class="payment-form__label"
            >
              Expiry date
            </label>
            <div class="payment-form__expiry-date">
              <div class="expiry-date-frame"></div>
            </div>
            <span
              v-if="errors['expiry-date']"
              class="payment-form__error-message"
            >
              Please enter a valid expiry date
            </span>
          </div>

          <div class="payment-form__cvv-container">
            <label
              for="payment-form__cvv"
              class="payment-form__label"
            >
              CVC/CVV
            </label>
            <div class="payment-form__cvv">
              <div class="cvv-frame"></div>
            </div>
            <span
              v-if="errors['cvv']"
              class="payment-form__error-message"
            >
              Please enter a valid cvc/cvv code
            </span>
          </div>
        </div>
      </div>

      <ui-button
        class="payment-form__button"
        type="submit"
        :disabled="disabled || !totalAmount"
        :is-loading="loading.form"
      >
        <span>
          Pay <ui-money :amount="totalAmount" :currency="currency" />
        </span>
      </ui-button>
    </div>
  </form>
</template>

<script>
import UiButton from '@/components/UiButton.vue';
import UiSpinner from '@/components/UiSpinner.vue';
import UiMoney from './UiMoney.vue';

export default {
  name: 'CheckoutByCard',

  components: {
    UiButton,
    UiSpinner,
    UiMoney,
  },

  props: {
    publicKey: {
      type: String,
      required: true,
    },
    currency: {
      type: Object,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    loading: {
      init: false,
      form: false,
    },
    disabled: true,
    frames: null,
    errors: {
      'card-number': false,
      'expiry-date': false,
      cvv: false,
    },
    tokenized: false,
  }),

  watch: {
    publicKey: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loading.init = true;

          const s = document.createElement('script');
          s.src = 'https://cdn.checkout.com/js/framesv2.min.js';

          document.head.appendChild(s);

          s.onload = () => {
            if (typeof window.Frames !== 'undefined') {
              this.frames = window.Frames;
              this.initialize();
            }
          };
        }
      },
    },
  },

  methods: {
    initialize() {
      this.frames.init({
        publicKey: this.publicKey,
        localization: {
          cardNumberPlaceholder: '0000 0000 0000 0000',
          expiryMonthPlaceholder: 'MM',
          expiryYearPlaceholder: 'YY',
          cvvPlaceholder: '000',
        },
        style: {
          base: {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#000000',
          },
          placeholder: {
            base: {
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#C4C4C4',
            },
          },
        },
      });

      this.frames.addEventHandler(this.frames.Events.FRAME_ACTIVATED, () => {
        this.loading.init = false;
      });

      this.frames.addEventHandler(this.frames.Events.FRAME_VALIDATION_CHANGED, (event) => {
        const e = event.element;

        if (event.isValid || event.isEmpty) {
          this.errors[e] = false;
        } else {
          this.errors[e] = true;
        }
      });

      this.frames.addEventHandler(this.frames.Events.CARD_VALIDATION_CHANGED, () => {
        this.disabled = !this.frames.isCardValid();
      });

      this.frames.addEventHandler(this.frames.Events.CARD_TOKENIZATION_FAILED, (error) => {
        this.tokenized = false;
        this.frames.enableSubmitForm();
      });

      this.frames.addEventHandler(this.frames.Events.CARD_TOKENIZED, () => {
        this.tokenized = true;
      });
    },
    async onSubmit(event) {
      event.preventDefault();

      this.loading.form = true;

      try {
        const form = event.target;
        const { token } = await this.frames.submitCard();

        this.frames.addCardToken(form, token);

        this.$emit('create-payment', {
          tokenized: this.tokenized,
          token,
        });
      } catch (e) {
        this.frames.enableSubmitForm();
      } finally {
        this.loading.form = false;
      }
    },
  },
};
</script>

<style lang="scss">
iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

.payment-form {
  margin: 0 auto;
  padding: 0 20px 20px;

  &__label {
    display: block;
    height: 10px;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #808080;

    margin-bottom: 4px;
  }

  &__card-data {
    margin-top: 27px;
  }

  &__card-number,
  &__expiry-date,
  &__cvv {
    position: relative;
    display: flex;
    height: 40px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    margin-top: 25px;
    margin-bottom: 36px;
  }

  &__date-container,
  &__cvv-container {
    flex-basis: 45%;
  }

  &__loader {
    width: 100%;
    height: 145px;
    max-width: 335px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    margin: 20px 0 30px;
  }

  &__error-message {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A01212;

    display: inline-block;

    margin-top: 5px;
  }

  &_hidden {
    display: none;
  }

  .frame {
    opacity: 0;
  }

  .frame--activated {
    opacity: 1;
    border-bottom: 1px solid #CCCCCC;
  }

  .frame--activated.frame--focus {
    border-bottom: 1px solid #000000;
  }

  .frame--activated.frame--invalid {
    border-bottom: 1px solid #A01212;
  }
}
</style>
