<template>
  <div
    class="ui-tips-selector"
    :class="{
      abVariantClass,
      'ui-tips-selector_disabled': disabled,
    }"
  >
    <ui-tip
      v-for="item in extendedTips"
      :key="item.label"
      :value="item.label"
      :currency="currency"
      :percentage="item.percentage"
      :caption="item.caption"
      :active="isSelectedTip(item)"
      :disabled="disabled"
      @click="$emit('select', item)"
    />
  </div>
</template>

<script>
import UiTip from './UiTip.vue';

export default {
  name: 'UiTips',
  components: {
    UiTip,
  },
  props: {
    tips: {
      type: Array,
      required: true,
    },
    currency: {
      type: Object,
      required: true,
    },
    abVariant: {
      type: String,
      default: '',
    },
    selectedTipId: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tip: null,
      currentAbVariant: 'DESIGN_ONE',
      abVariants: [
        {
          className: '',
          buttonId: 0,
          value: 'DESIGN_ONE',
          showCaption: false,
        },
        {
          className: 'tips__design-one-caption',
          buttonId: 0,
          value: 'DESIGN_ONE_CAPTION',
          showCaption: true,
        },
        {
          className: 'tips__design-two-button-two',
          buttonId: 2,
          value: 'DESIGN_TWO_BUTTON_TWO',
          showCaption: false,
        },
        {
          className: 'tips__design-two-button-three',
          buttonId: 3,
          value: 'DESIGN_TWO_BUTTON_THREE',
          showCaption: false,
        },
        {
          className: 'tips__design-two-button-two-caption',
          buttonId: 2,
          value: 'DESIGN_TWO_BUTTON_TWO_CAPTION',
          showCaption: true,
        },
        {
          className: 'tips__design-two-button-three-caption',
          buttonId: 3,
          value: 'DESIGN_TWO_BUTTON_THREE_CAPTION',
          showCaption: true,
        },
      ],
    };
  },
  computed: {
    captionList() {
      return [
        'Good',
        'Great',
        'Excellent',
        'Wow!',
      ];
    },
    extendedAbVariant() {
      return this.abVariants.find(({ value }) => value === this.currentAbVariant);
    },
    abVariantClass() {
      return this.extendedAbVariant.className;
    },
    extendedTips() {
      return this.tips.map((tip, index) => ({
        ...tip,
        id: index + 1,
        caption: this.extendedAbVariant.showCaption ? this.captionList[index] : '',
      }));
    },
  },
  methods: {
    isSelectedTip(tip) {
      return tip.id === this.selectedTipId;
    },
    setTip(tip) {
      this.$emit('select', tip);
    },
    selectAbVariantTipButton() {
      const { buttonId } = this.extendedAbVariant;
      const tip = this.extendedTips.find(({ id }) => id === buttonId);

      if (tip) {
        this.setTip(tip);
      }
    },
  },
  mounted() {
    if (this.abVariant) {
      this.currentAbVariant = this.abVariant;
    }

    this.selectAbVariantTipButton();
  },
};
</script>

<style lang="scss">
  .tips__design-one-caption,
  .tips__design-two-button-two-caption,
  .tips__design-two-button-three-caption {
    .ui-tip {
      .ui-tip__title {
        height: 54px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        line-height: 24px;

        .ui-tip__caption {
          display: block;
        }
      }
    }
  }

  .tips__design-two-button-two,
  .tips__design-two-button-two-caption,
  .tips__design-two-button-three,
  .tips__design-two-button-three-caption {
    .ui-tip {
      .ui-tip__title {
        position: relative;
        background: #FFFFFF;
        color: #000000;
        text-shadow: none;
        opacity: 1;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 8px;
          padding: 2px;
          background: linear-gradient(to bottom, #FFE059, #FF8263);
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
        }
      }
    }

    .ui-tip.ui-tip_active {
      border: none;

      .ui-tip__title {
        color: #fff;
        background: linear-gradient(180deg, #FFD700 0%, #F33229 100%);

        &::before {
          display: none;
        }
      }
    }
  }

  .ui-tips-selector {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    &_disabled {
      opacity: 0.25;
    }

    > * {
      flex-basis: 24%;
    }
  }

</style>
