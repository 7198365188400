<template>
  <div
    class="user-reviews"
    :key="counter"
    :class="{
      'user-reviews_icon': isGoodRate,
      'user-reviews_text': !isGoodRate,
    }"
  >
    <div class="user-reviews__title">
      {{ title }}
    </div>
    <div class="user-reviews__body">
      <div class="user-reviews__inner">
        <ui-icon-button
          v-for="item in visibleReviews"
          :key="item.id"
          name=""
          class="user-review"
          :class="{
            'user-review_active': isSelected(item),
          }"
          @click="toggle(item)"
        >
          <img
            v-if="isGoodRate"
            :src="item.icon"
            class="user-review__icon"
          />
          <span class="user-review__title">
            {{ $t(item.name, countryIso) }}
          </span>
        </ui-icon-button>
      </div>
    </div>
  </div>
</template>

<script>
import reviewsConfig from '@/config/reviews';
import shuffle from '@/utils/shuffle';
import UiIconButton from './UiIconButton.vue';

export default {

  components: {
    UiIconButton,
  },

  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    rate: {
      type: Number,
      required: true,
    },
    reviews: {
      type: Array,
      required: true,
    },
    locationType: String,
    countryIso: String,
  },

  data() {
    return {
      counter: 0,
      selected: this.value,
    };
  },

  computed: {
    isGoodRate() {
      return this.rate > 3;
    },
    title() {
      return this.isGoodRate
        ? this.$t('reviews.titleGood')
        : this.$t('reviews.titleBad');
    },
    visibleReviews() {
      const reviews = this.reviews
        .filter(({ workPlaceType }) => {
          return workPlaceType === this.locationType;
        })
        .filter(({ type }) => {
          return this.rate > 0 && type === (this.isGoodRate ? 'COMPLIMENT' : 'HATE');
        })
        .filter(({ status }) => {
          return !this.isGoodRate || status === 'ACTIVE';
        })
        .filter(({ starsCnt }) => {
          if (this.isGoodRate) {
            return this.rate >= starsCnt;
          }

          return starsCnt === this.rate;
        })
        .map((item) => {
          return {
            ...item,
            // eslint-disable-next-line global-require, import/no-dynamic-require
            icon: this.isGoodRate ? require(`@/assets/reviews/${this.getImage(item)}`) : null,
          };
        });

      shuffle(reviews);

      return reviews;
    },
  },

  watch: {
    visibleReviews() {
      this.counter += 1;
    },
  },

  methods: {
    getImage({ id, name, workPlaceType }) {
      return reviewsConfig?.[workPlaceType.toLowerCase()]?.[name] || 'noimage.png';
    },
    isSelected(item) {
      return this.selected.findIndex(({ id }) => id === item.id) > -1;
    },
    toggle(item) {
      const index = this.selected.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }

      this.$emit('input', this.selected);
    },
  },
};
</script>

<style lang="scss">
.user-reviews {
  &__body {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  &__inner {
    width: 100%;
    display: flex;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 14px;
  }

  .user-review {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5px 5px;
    margin: 2px 0;
    min-width: 70px;
    box-sizing: border-box;
    position: relative;
    color: black;

    &__icon {
      width: 50px;
      margin: 15px 0 13px;
    }

    &__title {
      text-align: center;
      font-size: 14px;
      line-height: 1.15;
      padding-bottom: 5px;
    }

    & + & {
      margin-left: 6px;
    }
  }

  &_icon &__inner {
    width: 100%;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-name: beautify-icon;
  }
  &_icon .user-review__title {
    border-bottom: 2px solid transparent;
  }
  &_icon .user-review_active .user-review__title {
    border-color: #d70a12;
  }

  &_text &__body {
    max-height: 150px;
  }
  &_text &__inner {
    width: 100%;
    flex-direction: column;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-name: beautify-text;
  }
  &_text .user-review {
    align-items: start;
    padding-left: 0;
    padding-right: 30px;
    width: 100%;
  }
  &_text .user-review__title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }
  &_text .user-review_active .user-review__title {
    font-weight: 500;
  }
  &_text .user-review_active:after {
    content: '✓';
    position: absolute;
    right: 15px;
  }
}

@keyframes beautify-icon {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes beautify-text {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.fr .user-reviews {
  &_text .user-reviews__body {
    max-height: 142px;
  }

  &_text .user-review__title {
    font-size: 13px;
  }
}
</style>
