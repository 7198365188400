<template>
  <base-layout class="menu__layout">
    <menu-background
      v-if="backgroundInfo"
      :background-url="backgroundInfo.link"
    />
    <menu-header
      :location-name="location.name"
      :contact-info="contactInfo"
    />
    <ui-selector
      v-if="showCategories"
      v-model="selectedCategoryIndex"
      :items="menuCategories"
      class="menu__categories-selector"
    />
    <div class="menu">
      <img
        v-for="menuPage in menuPagesForShow"
        :key="menuPage.link"
        class="menu__image"
        :src="menuPage.link"
        alt="menu"
      >
    </div>
    <menu-footer />
    <div class="menu__sticky-footer">
      <ui-button class="menu__tips-button" @click="goToTips">
        Want to leave a tip?
      </ui-button>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import MenuHeader from '@/components/menu/MenuHeader.vue';
import MenuFooter from '@/components/menu/MenuFooter.vue';
import UiButton from '@/components/UiButton.vue';
import UiSelector from '@/components/UiSelector.vue';
import MenuBackground from '@/components/menu/MenuBackground.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Menu',

  components: {
    MenuBackground,
    UiSelector,
    UiButton,
    MenuFooter,
    MenuHeader,
    BaseLayout,
  },

  props: {
    payoutId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedCategoryIndex: 0,
    };
  },

  computed: {
    ...mapState({
      backgroundInfo: (state) => state.menu.backgroundInfo,
      contactInfo: (state) => state.menu.contactInfo,
      location: (state) => state.user.location,
    }),
    ...mapGetters({
      menuItems: 'menu/sortedMenuItems',
      showCategories: 'menu/showCategories',
    }),
    menuPagesForShow() {
      return this.menuItems[this.selectedCategoryIndex]?.pages || [];
    },
    menuCategories() {
      return this.menuItems.map((item, index) => ({
        id: index,
        title: item.section,
      }));
    },
  },

  methods: {
    ...mapActions({
      loadLocation: 'user/loadLocation',
      loadMenuInfo: 'menu/loadMenuInfo',
    }),
    goToTips() {
      this.$router.push({ name: 'home' });
    },
  },

  created() {
    this.loadLocation({ payoutId: this.payoutId });
    this.loadMenuInfo({ payoutId: this.payoutId });
  },
};
</script>
