<template>
  <div class="menu-header">
    <div class="menu-header__left-side">
      <ui-icon-button
        name="left-arrow"
        size="28px"
        class="menu-header__back-button"
        @click="goToLocation"
      />
    </div>
    <div class="menu-header__info">
      <div v-if="locationName" class="menu-header__location">
        {{ locationName }}
      </div>
      <div v-if="contactInfo.address" class="menu-header__address">
        {{ contactInfo.address }}
      </div>
    </div>
    <div class="menu-header__right-side">
      <a
        v-if="contactInfo.instagramUrl"
        :href="contactInfo.instagramUrl"
        target="_blank"
      >
        <ui-icon-button name="instagram" size="28px" />
      </a>
      <a
        v-else-if="contactInfo.facebookUrl"
        :href="contactInfo.facebookUrl"
        target="_blank"
      >
        <ui-icon-button name="facebook" size="28px" />
      </a>
      <a
        v-if="contactInfo.phoneNumber"
        :href="`tel:${contactInfo.phoneNumber}`"
        class="menu-header__phone"
      >
        <ui-icon-button
          name="phone"
          size="16px"
        />
      </a>
    </div>
  </div>
</template>

<script>
import UiIconButton from '@/components/UiIconButton.vue';

export default {
  name: 'MenuHeader',

  components: {
    UiIconButton,
  },

  props: {
    locationName: {
      type: String,
      default: '',
    },
    contactInfo: {
      type: Object,
      required: true,
    },
  },

  methods: {
    goToLocation() {
      this.$router.push({ name: 'location' });
    },
  },
};
</script>
