<template>
  <ui-modal
    v-model="show"
    v-bind="$attrs"
    class="modal-status"
    :class="{
      [`modal-status_${status}`]: status,
    }"
    v-on="$listeners"
    :closable="false"
  >
    <template #header>
      <img
        v-if="status === 'success'"
        src="@/assets/ok.svg"
        class="modal-status__image"
        alt="Success image"
      />
      <img
        v-if="status === 'error'"
        src="@/assets/payment-failed.svg"
        class="modal-status__image"
        alt="Failed image"
      />

      <div>
        {{ $t(`modal.${status}.title`) }}
      </div>
    </template>

    <template #default>
      <div
        v-if="showFeedbackSuccess"
        class="modal-status__feedback-text"
      >
        <div
          v-for="(text, index) in $t(`modal.${status}.text`)"
          :key="index"
        >
          {{ text }}
        </div>
      </div>
      <div
        v-else-if="status === 'error'"
        class="modal-status__text"
      >
        {{ $t(`modal.${status}.text`) }}
      </div>
      <ui-feedback-links
        v-if="showFeedbackLinks"
        :feedback-links="feedbackLinks"
      />
      <ui-receipt
        v-if="showReceipt"
        :is-loading="isLoading"
        @checked="toggle"
        @send="$emit('send-receipt', $event)"
      ></ui-receipt>
      <ui-button
        v-if="showOk"
        @click="$emit('closed', $event)"
        class="modal-status__button"
        :small="!showFeedbackLinks"
      >
        {{ showFeedbackSuccess ? 'No, thanks' : 'Ok' }}
      </ui-button>
      <ui-socials
        v-if="status === 'success'"
        @clicked-link="clickedLink"
      />
    </template>
  </ui-modal>
</template>

<script>
import { setTransactionHash } from '@/utils/transactionCollisions';
import UiModal from './UiModal.vue';
import UiReceipt from './UiReceipt.vue';
import UiSocials from './UiSocials.vue';
import UiButton from './UiButton.vue';
import UiFeedbackLinks from './UiFeedbackLinks.vue';

export default {

  inheritAttrs: false,

  components: {
    UiModal,
    UiReceipt,
    UiButton,
    UiSocials,
    UiFeedbackLinks,
  },

  props: {
    status: {
      type: String,
      required: true,
    },
    cardPay: {
      type: Boolean,
      required: true,
    },
    feedbackSent: {
      type: Boolean,
      required: true,
    },
    rate: Number,
    isLoading: Boolean,
    feedbackLinks: Object,
  },

  data: () => ({
    checked: null,
    show: true,
  }),

  computed: {
    showReceipt() {
      return !this.cardPay && !this.feedbackSent && this.status === 'success';
    },
    showOk() {
      return !this.checked;
    },
    showFeedbackLinks() {
      return this.feedbackLinks && Object.keys(this.feedbackLinks).length !== 0 && this.rate === 5;
    },
    showFeedbackSuccess() {
      return this.showFeedbackLinks && this.status === 'success' && this.rate === 5;
    },
  },

  methods: {
    toggle(checked) {
      this.checked = checked;
      this.$emit('toggle-receipt');
    },
    clickedLink(linkName) {
      this.$emit('clicked-link', linkName);
    },
    saveInfoAboutTransaction() {
      const { locationPayoutId } = this.$route.params;
      const { amount, staffId } = this.$route.query;
      setTransactionHash({ locationPayoutId, amount, staffId });
    },
  },

  created() {
    this.saveInfoAboutTransaction();
  },
};
</script>

<style lang="scss">
  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .modal-status {
    text-align: center;

    &__image {
      margin-bottom: 17px;
    }

    &__text {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 35px;
    }

    &__feedback-text {
      font-size: 14px;
      line-height: 20px;
      color: #808080;
      margin-bottom: 35px;
    }

    &__button {
      display: inline-block;
    }

    &_error {
      color: #a01212;
    }
  }

  .modal-status .ui-modal-wrapper.opened {
    background-image: linear-gradient(#fcb77b 120px, #a01212 100%);
  }
</style>
