import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';
import i18n from '@/plugins/i18n';

extend('required', {
  ...required,
  message: () => i18n.t('validationErrors.required'),
});

extend('email', {
  ...email,
  message: () => i18n.t('validationErrors.email'),
});

extend('min', {
  validate(value, length) {
    if (!min.validate(value, { length })) {
      return i18n.t('validationErrors.min', { length });
    }

    return true;
  },
});
