import Storage from '@/class/Storage';
import factory from '../factory';

/**
 * @var {Storage}
 */
const storage = new Storage(localStorage);
const COOKIE_KEY = 'cookie-policy';

export default factory({

  namespaced: true,

  state: {
    isAccepted: false,
  },
  actions: {
    loadCookie({ commit }) {
      const cookie = storage.get(COOKIE_KEY);

      if (cookie) {
        commit('set', { isAccepted: true });
      }
    },
    acceptCookie({ commit }) {
      storage.set(COOKIE_KEY, true);
      commit('set', { isAccepted: true });
    },
  },
});
