<template>
  <ui-card>
    <div class="tip-selector-card">
      <div class="tip-selector-card__title">{{ $t('search.chooseOrTipManually') }}</div>
      <slot />
    </div>
  </ui-card>
</template>

<script>
import UiCard from './UiCard.vue';

export default {
  name: 'TipSelectorCard',
  components: {
    UiCard,
  },
};
</script>
<style lang="scss">
  .tip-selector-card {
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
</style>
