<template>
  <ui-modal
    v-bind="$attrs"
    class="modal-privacy"
    v-on="$listeners"
  >
    <template v-if="$scopedSlots.activator" #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #default="{ close }">
      <privacy-policy-gb v-if="isUk || isSwitzerland || isUAE" />
      <privacy-policy-de v-if="isGermany" />
      <ui-button @click="close">
        {{ $t('modal.privacy.button') }}
      </ui-button>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from '@/components/UiModal.vue';
import UiButton from '@/components/UiButton.vue';

import PrivacyPolicyGb from '@/components/PrivacyPolicyGb.vue';
import PrivacyPolicyDe from '@/components/PrivacyPolicyDe.vue';

export default {

  inheritAttrs: false,

  components: {
    UiModal,
    UiButton,
    PrivacyPolicyGb,
    PrivacyPolicyDe,
  },

  computed: {
    country() {
      return this.$store.state.user.location.country;
    },
    isUk() {
      return this.country === 'gb';
    },
    isGermany() {
      return this.country === 'de';
    },
    isUAE() {
      return this.country === 'ae';
    },
    isSwitzerland() {
      return this.country === 'ch';
    },
  },
};
</script>

<style lang="scss">
.modal-privacy {
  .document {
    font-family: "RobotoRegular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;
    color: #2c2c2c;

    p {
      margin-bottom: 25px;
    }

    a {
      color: #A01212;
    }

    ul, ol {
      margin-bottom: 25px;
      padding-left: 25px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    .title, h2 {
      font-family: "RobotoMedium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #000;
      margin-top: 0;
      margin-bottom: 32px;
    }

    .updated-date {
      display: inline-block;
      font-family: "RobotoMedium", sans-serif;
      font-weight: 500;
      color: #808080;
      margin-bottom: 20px;
    }
  }
}
</style>
