<template>
  <div
    class="user-staff"
    :class="{
      'user-staff_multipay': multipay,
      'user-staff_receipt': receipt,
    }"
    v-on="$listeners"
  >
    <user-avatar
      :payout-id="staff.payoutId"
      :size="multipay ? 'tiny': 'small'"
    />
    <div class="user-staff__description">
      <h3 class="user-staff__name">
        <template v-if="staff.nickName">
          {{ staff.nickName }}
        </template>
        <template v-else-if="fio">
          {{ fio }}
        </template>
        <span v-else class="user-staff__empty">
          No name
        </span>
      </h3>
      <div
        v-if="labeled"
        class="user-staff__label"
      >
        <span
          v-for="item in staff.service"
          :key="item"
        >
          {{ item }}
        </span>
      </div>
      <span class="user-staff__role">
        <slot>
          {{ staffTypeLabel }}
        </slot>
      </span>
    </div>
    <ui-checkbox
      v-if="selectable"
      :value="selected"
      rounded
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import getFio from '@/utils/getFio';
import UiCheckbox from './UiCheckbox.vue';
import UserAvatar from './UserAvatar.vue';

export default {

  components: {
    UiCheckbox,
    UserAvatar,
  },

  props: {
    staff: {
      type: Object,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    multipay: Boolean,
    labeled: Boolean,
    receipt: Boolean,
  },

  async mounted() {
    try {
      await this.getStaffTypes();
    } catch (e) {
      console.log(e);
    }
  },

  computed: {
    ...mapState('user', [
      'location',
    ]),
    fio() {
      return getFio(this.staff);
    },
    staffTypeLabel() {
      return this.$store.getters['staffTypes/staffTypeName'](this.staff.staffType);
    },
  },

  methods: {
    ...mapActions('staffTypes', [
      'getStaffTypes',
    ]),
  },
};
</script>

<style lang="scss">
.user-staff {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.18);
  border-radius: 12px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 15px;

  &_multipay {
    .user-staff {
      &__description {
        padding: 0 4px 0 24px;
      }
      &__name {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  &_receipt {
    .user-staff {
      &__description {
        padding: 0 4px 0 24px;
      }

      &__name {
        font-weight: normal;
        font-size: 16px;
        line-height: 13px;
        margin-bottom: 4px;
      }

      &__label {
        margin-bottom: 17px;

        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 12px;
          color: #818181;

          max-width: 99%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  &_inner {
    padding: 0;
    box-shadow: none;
  }

  &__description {
    flex: 1;
    color: #000;
    padding: 0 15px;
    overflow: hidden;
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 8px;
  }

  &__empty {
    color: gray;
  }

  &__role {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
