var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"ui-button",class:{
    'ui-button_pay': _vm.isPay,
    'ui-button_giropay': _vm.giroPay,
    'ui-button_small': _vm.small,
    'ui-button_link': _vm.link,
    'ui-button_outlined': _vm.outlined,
    'ui-button_transparent': _vm.transparent,
    'ui-button_ghost': _vm.ghost,
    'ui-button_paypal': _vm.payPal,
    'ui-button_disabled': _vm.disabled,
  },attrs:{"type":_vm.type,"disabled":_vm.disabled}},'component',_vm.$attrs,false),_vm.listeners),[(_vm.isLoading)?_c('ui-spinner'):(_vm.name)?_c('ui-icon',{staticClass:"ui-button__icon",attrs:{"name":_vm.name}}):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }