<template>
  <div class="menu-footer">
    <ui-icon
      name="easytip-white"
      width="95px"
      height="25px"
    />
  </div>
</template>

<script>
import UiIcon from '@/components/UiIcon.vue';

export default {
  name: 'MenuFooter',

  components: {
    UiIcon,
  },
};
</script>
