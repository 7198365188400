import request from './request';

/**
 * Staff profile data.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getStaff = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/staffs/${payoutId}`,
});

/**
 * Avatar image.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getAvatar = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/code/image/${payoutId}`,
  responseType: 'blob',
});

/**
 * Location info.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getLocation = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}`,
});

/**
 * Location active staff members.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getLocationStaffs = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}/staffs`,
});

/**
 * Location groups.
 *
 * @param  {string} locationId
 * @return {Promise}
 */
const getLocationGroups = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}/groups`,
});

/**
 * Sub locations.
 *
 * @param  {string} locationId
 * @return {Promise}
 */
const getSubLocations = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}/sublocations`,
});

/**
 * Location reviews (compliments and issues).
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getLocationReviews = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/restaurants/${payoutId}/reviews`,
});

/**
 * Get fee amount for specified total tips.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const getLocationFee = (data, version = 3) => request.pay({
  method: 'post',
  url: `/v${version}/payment/fee`,
  data,
});

/**
 * Location group parameters.
 *
 * @param  {string} locationId
 * @param  {string} payoutId
 * @return {Promise}
 */
const getGroup = (locationId, payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${locationId}/groups/${payoutId}`,
});

/**
 * Send feedback comment without payout tips.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const sendFeedback = (data) => request.pay({
  method: 'post',
  url: '/v4/feedback',
  data,
});

/**
 * Create payment invoice.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const createPayment = (data, version = 3) => request.pay({
  method: 'post',
  url: `/v${version}/payment`,
  data,
});

/**
 * Create giro pay invoice.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const createGiroPayPayment = (data) => request.pay({
  method: 'post',
  url: '/v4/payment/giropay',
  data,
});

/**
 * Create apple / google pay invoice.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const createMobilePayment = (data, version = 3) => request.pay({
  method: 'post',
  url: `/v${version}/payment/applepay`,
  data,
});

/**
 * Send payment confirm.
 *
 * @param  {Object} params
 * @param  {Number} version
 * @return {Promise}
 */
const confirmPayment = (params, version = 3) => request.pay({
  method: 'post',
  url: `/v${version}/payment/confirm`,
  params,
});

/**
 * Send giro pay payment confirm.
 *
 * @param  {Object} params
 * @param  {Number} version
 * @return {Promise}
 */
const confirmGiroPayPayment = (params) => request.pay({
  method: 'post',
  url: '/v4/payment/confirm',
  params,
});

/**
 * Send receipt to email
 *
 * @param {Object} params
 * @return {Promise}
 */
const sendReceiptEmail = (data) => request.pay({
  method: 'post',
  url: '/v3/payment/receipt',
  data,
});

/**
 * Get intent by session.
 *
 * @param  {string} sessionId
 * @param  {number} workplacePayoutId
 * @param  {number} version
 * @return {Promise}
 */
const getIntent = ({ sessionId, workplacePayoutId }, version = 3) => request.pay({
  method: 'get',
  url: `/v${version}/payment/intentBySession`,
  params: {
    sessionId,
    workplacePayoutId,
  },
});

/**
 * Get stripe public key for specified location.
 *
 * @param  {Object} workplacePayoutId
 * @return {Promise}
 */
const getPublicKey = (workplacePayoutId) => request.admin({
  method: 'get',
  url: '/v1/public/publicKey',
  params: {
    workplacePayoutId,
  },
});

/**
 * Send `sorry` phone after complete payment.
 *
 * @param  {string} paymentIntentId
 * @param  {string} phoneNumber
 * @param  {number} version
 * @return {Promise}
 */
const sendPhone = ({ phoneNumber, feedbackId }) => request.pay({
  method: 'post',
  url: '/v4/feedback/enrich',
  params: {
    feedbackId,
  },
  data: {
    phoneNumber,
  },
});

const sendPhoneV3 = ({ paymentIntentId, phoneNumber }) => request.pay({
  method: 'post',
  url: '/v3/payment/enrich',
  params: {
    paymentIntentId,
  },
  data: {
    phoneNumber,
  },
});

const getSeoKey = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/staffs/${payoutId}/seo`,
});

const getStaffTypes = () => request.admin({
  method: 'get',
  url: '/v1/public/handbook/staffTypes',
});

const getStaffTypesVersion = () => request.admin({
  method: 'get',
  url: '/v1/public/handbook/versions',
});

/**
 * Create checkout payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createCheckoutPayment = (data) => request.pay({
  method: 'post',
  url: '/v5/payment',
  data,
});

/**
 * Get checkout payment fee.
 *
 * @param {Object} data
 * @return {Promise}
 */
const getCheckoutPaymentFee = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/fee',
  data,
});

/**
 * Get checkout public key.
 *
 * @param {Object} params
 * @return {Promise}
 */
const getCheckoutPaymentPublicKey = (params) => request.admin({
  method: 'get',
  url: '/v1/public/payments/publicKey',
  params,
});

/**
 * Send checkout bad feedback info.
 *
 * @param {Object} data
 * @return {Promise}
 */
const sendCheckoutPaymentFeedbackInfo = ({ checkoutPaymentId, ...data }) => request.pay({
  method: 'post',
  url: `/v5/payment/${checkoutPaymentId}/enrich`,
  data,
});

/**
 * Send checkout feedback.
 *
 * @param {Object} data
 * @return {Promise}
 */
const sendCheckoutFeedback = (data) => request.pay({
  method: 'post',
  url: '/v5/feedback',
  data,
});

/**
 * Send checkout feedback.
 *
 * @param {Object} data
 * @return {Promise}
 */
const sendCheckoutFeedbackInfo = ({ feedbackId, ...data }) => request.pay({
  method: 'post',
  url: `/v5/feedback/${feedbackId}/enrich`,
  data,
});

/**
 * Create Checkout ApplePay payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createCheckoutApplePayPayment = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/applepay',
  data,
});

/**
 * Validate Checkout ApplePay session.
 *
 * @param {Object} data
 * @return {Promise}
 */
const validateCheckoutAppleSession = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/applepay/validateSession',
  data,
});

/**
 * Confirm checkout pay by card.
 *
 * @param {Object} params
 * @return {Promise}
 */
const checkoutPaymentConfirm = ({ status, ...params }) => request.pay({
  method: 'post',
  url: `/v5/payment/${status}`,
  params,
});

/**
 * Create Checkout GooglePay payment.
 *
 * @param {Object} params
 * @return {Promise}
 */
const createCheckoutGooglePayPayment = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/googlepay',
  data,
});

/**
 * Send checkout receipt to email.
 *
 * @param {Object} params
 * @return {Promise}
 */
const sendCheckoutReceiptEmail = ({ checkoutPaymentId, ...data }) => request.pay({
  method: 'post',
  url: `/v5/payment/${checkoutPaymentId}/receipt`,
  data,
});

/**
 * Get checkout payment configuration.
 *
 * @return {Promise}
 */
const getCheckoutConfiguration = () => request.admin({
  method: 'get',
  url: '/v1/public/paymentConfiguration',
});

/**
 * Create paypal payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createPaypalPayment = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/paypal',
  data,
});

const getMenuInfo = ({ workplaceId, ...params }) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${workplaceId}/menu`,
  params,
});

export default {
  getStaff,
  getAvatar,
  getLocation,
  getLocationStaffs,
  getLocationGroups,
  getSubLocations,
  getLocationReviews,
  getLocationFee,
  getGroup,
  sendFeedback,
  createPayment,
  createGiroPayPayment,
  createMobilePayment,
  confirmPayment,
  confirmGiroPayPayment,
  getIntent,
  getPublicKey,
  sendPhone,
  sendPhoneV3,
  sendReceiptEmail,
  getSeoKey,
  getStaffTypes,
  getStaffTypesVersion,
  createCheckoutPayment,
  getCheckoutPaymentFee,
  getCheckoutPaymentPublicKey,
  sendCheckoutPaymentFeedbackInfo,
  sendCheckoutFeedback,
  sendCheckoutFeedbackInfo,
  createCheckoutApplePayPayment,
  validateCheckoutAppleSession,
  checkoutPaymentConfirm,
  createCheckoutGooglePayPayment,
  sendCheckoutReceiptEmail,
  getCheckoutConfiguration,
  createPaypalPayment,
  getMenuInfo,
};
