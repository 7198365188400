<template>
  <div class="menu-background">
    <div class="menu-background__side">
      <img
        class="menu-background__image menu-background__image_top"
        :src="backgroundUrl"
      >
    </div>
    <div class="menu-background__side">
      <img
        class="menu-background__image menu-background__image_bottom"
        :src="backgroundUrl"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuBackground',

  props: {
    backgroundUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
