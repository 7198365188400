import api from '@/api';
import router from '@/router';

import factory from '../factory';

export default factory({

  namespaced: true,

  state: () => ({
    backgroundInfo: null,
    contactInfo: {},
    menuItems: [],
  }),

  getters: {
    previewMenuLink(state) {
      return state.menuItems[0]?.pages?.[0]?.link || '';
    },
    sortedMenuItems(state) {
      // Данные приходят в форме массива нарезанных PDF-ок,
      // не связанных по секциям и не отсортированные.
      // Сначала создаем мапу для сбора DPF-ок по секциям
      const menuItemsMap = {};
      state.menuItems.forEach((item) => {
        const sectionName = item.section?.name || 'Menu';
        const sectionOrder = item.section?.order || 0;
        if (menuItemsMap[sectionName]) {
          menuItemsMap[sectionName].pages = [
            ...menuItemsMap[sectionName].pages,
            {
              order: item.displayOrder,
              pages: [...item.pages],
            },
          ];
        } else {
          menuItemsMap[sectionName] = {
            order: sectionOrder,
            pages: [{ pages: item.pages, order: item.displayOrder }],
          };
        }
      });
      // Далее собираем эту мапу в массив, сортируем массив секций
      // по нужному порядку секций и сортируем страницы внутри
      // каждой секции по их порядку
      return Object.entries(menuItemsMap)
        .map(([key, data]) => ({
          section: key,
          pages: data.pages,
          order: data.order,
        }))
        .sort((a, b) => a.order - b.order)
        .map((item) => ({
          ...item,
          pages: item.pages.sort((a, b) => a.order - b.order)
            .map((page) => [...page.pages])
            .flat(),
        }));
    },
    showCategories(state, getters) {
      return getters.sortedMenuItems?.length > 1;
    },
  },

  actions: {
    async loadMenuInfo({ state, commit }, { payoutId, hardRefresh = false }) {
      // Not load data twice
      if (state.contactInfo?.address && !hardRefresh) {
        return;
      }
      const preview = Boolean(router.currentRoute.query.preview) || null;
      try {
        const { data } = await api.getMenuInfo({ workplaceId: payoutId, preview });
        commit('set', {
          backgroundInfo: data.backgroundInfo,
          contactInfo: data.contactInfo,
          menuItems: data.menuItems,
        });
      } catch (e) {
        console.error(e);
        router.push({ name: 'home' });
      }
    },
  },
});
