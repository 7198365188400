<template>
  <ui-modal
    v-model="show"
    v-bind="$attrs"
    class="modal-sorry"
    v-on="$listeners"
  >
    <template #header>
      <div class="modal-sorry__title">
        {{ $t('modal.sorry.title') }}
      </div>
      <img
        src="@/assets/sorry.png"
        class="modal-sorry__image"
      />
    </template>

    <template #default="{ close }">
      <div class="modal-sorry__text">
        {{ $t('modal.sorry.text') }}
      </div>
      <div class="modal-sorry__control">
        <ui-input
          v-model="inputValue"
          :placeholder="$t('modal.sorry.placeholder')"
          small
        />
      </div>

      <ui-button
        class="modal-sorry__button"
        :disabled="!inputValue || isLoading"
        :is-loading="isLoading"
        small
        @click="apply(close)"
      >
        {{ $t('modal.sorry.button.ok') }}
      </ui-button>
      <ui-button
        class="modal-sorry__button"
        :disabled="isLoading"
        link
        @click="close"
      >
        {{ $t('modal.sorry.button.notNow') }}
      </ui-button>
      <ui-socials @clicked-link="clickedLink" />
    </template>
  </ui-modal>
</template>

<script>
import UiModal from './UiModal.vue';
import UiInput from './UiInput.vue';
import UiButton from './UiButton.vue';
import UiSocials from './UiSocials.vue';

export default {

  inheritAttrs: false,

  components: {
    UiModal,
    UiInput,
    UiButton,
    UiSocials,
  },

  props: {
    value: null,
    isLoading: Boolean,
  },

  data: () => ({
    show: true,
    inputValue: '',
    receipted: false,
  }),

  computed: {
    verifyEmail() {
      return this.inputValue.includes('@');
    },
  },

  methods: {
    apply(close) {
      if (this.receipted) {
        this.$emit('receipt', this.receipted);
      }

      this.$emit('input', this.inputValue);

      close();
    },
    clickedLink(linkName) {
      this.$emit('clicked-link', linkName);
    },
  },
};
</script>

<style lang="scss">
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.modal-sorry {
  text-align: center;

  &__title {
    font-size: 16px;
    line-height: 18px;
  }

  &__image {
    margin-top: 16px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
  }

  &__control {
    margin: 35px 10px;
    text-align: center;

    .ui-input {
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      padding-bottom: 4px;
    }
  }

  &__button {
    margin: 0 auto;
  }

  &__checkbox {
    padding: 0 10px 30px 10px;
  }
}

.modal-sorry .ui-modal-wrapper.opened {
  background-image: linear-gradient(#fcb77b 120px, #a01212 100%);
}
</style>
