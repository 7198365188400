<template>
  <label class="ui-switch-wrapper" :style="styleVars">
    <div class="ui-switch">
      <input :checked="value" type="checkbox" @input="$emit('input', !value)">
      <div class="ui-switch__slider round" />
    </div>
    <div v-if="label" class="ui-switch__label">{{ label }}</div>
  </label>
</template>

<script>
export default {
  name: 'UiSwitcher',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '#FFFFFF',
    },
    sliderColor: {
      type: String,
      default: '#FFFFFF4d',
    },
    trackColor: {
      type: String,
      default: '#FFFFFF',
    },
  },

  computed: {
    styleVars() {
      return {
        '--ui-switcher-label-color': this.labelColor,
        '--ui-switcher-slider-color': this.sliderColor,
        '--ui-switcher-track-color': this.trackColor,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-switch-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  .ui-switch {
    min-width: 56px;
    width: 56px;
    height: 32px;
    min-height: 32px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .ui-switch__slider {
        background-color: #A01212;
        &:before {
          -webkit-transform: translateX(24px);
          -ms-transform: translateX(24px);
          transform: translateX(24px);
        }
      }
    }

    &__label {
      margin-left: 12px;
      color: var(--ui-switcher-label-color);
    }

    .ui-switch__slider {
      width: 56px;
      height: 32px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--ui-switcher-slider-color);
      -webkit-transition: .4s;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 24px;
        width: 24px;
        left: 4px;
        bottom: 4px;
        background-color: var(--ui-switcher-track-color);
        -webkit-transition: .4s;
        transition: .4s;
      }

      &.round {
        border-radius: 34px;
      }

      &.round:before {
        border-radius: 50%;
      }
    }
  }
}
</style>
