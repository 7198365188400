<template>
  <ui-button
    v-bind="$attrs"
    :is-loading="loading"
    pay-pal
    @click.prevent="$emit('submit', () => submit())"
  >
    Pay by paypal
  </ui-button>
</template>

<script>
import api from '@/api';
import UiButton from '@/components/UiButton.vue';

export default {

  components: {
    UiButton,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    isStaff: {
      type: Boolean,
      required: true,
    },
    amplitudePaymentData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    filteredData() {
      const {
        cards,
        comment,
        stars,
        payoutId,
        phoneNumber,
        totalAmount,
        kycProcessed,
        rate,
        locationId,
        payPalPayment,
        ...data
      } = this.data;

      return {
        ...data,
        amount: +totalAmount,
        listPayment: [{
          payoutId,
          comment,
          stars,
          cards,
          amount: data.amount,
        }],
        backUrl: {
          rate,
          locationId,
          payPalPayment,
        },
      };
    },
  },

  methods: {
    redirectUrl(status) {
      const url = this.filteredData.backUrl;

      return window.location.origin + this.$router.resolve({
        path: `${url.locationId}/${status}`,
        query: {
          payPalPayment: url.payPalPayment,
          rate: url.rate,
          amount: this.filteredData.amount,
          staffId: this.filteredData.listPayment?.[0]?.payoutId || 0,
        },
      }).href;
    },
    async submit() {
      const {
        backUrl,
        ...otherData
      } = this.filteredData;

      this.loading = true;

      this.$amplitude.event(
        `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAYPAL_PAY_PRESS`,
        this.amplitudePaymentData,
      );

      try {
        const { data } = await api.createPaypalPayment({
          ...otherData,
          successUrl: this.redirectUrl('success'),
          errorUrl: this.redirectUrl('error'),
        });

        if (data?.redirectLink) {
          window.location.href = data?.redirectLink;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
