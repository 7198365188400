<template>
  <base-layout vertical-centering>
    <dialogue-card
      :title="$t('dialogue.notActive.title')"
      :description="$t('dialogue.notActive.description')"
      class="not-active-dialogue"
    >
      <template #image>
        <img
          class="not-active-dialogue__image"
          src="@/assets/sorry-big.png"
        />
      </template>
    </dialogue-card>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import DialogueCard from '@/components/DialogueCard.vue';

export default {
  name: 'NotActive',
  components: { DialogueCard, BaseLayout },
};
</script>

<style lang="scss">
.not-active-dialogue {
  &__image {
    height: 64px;
    width: 64px;
  }
}
</style>
