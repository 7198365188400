<template>
  <div class="ui-selector">
    <div
      v-for="item in items"
      :key="item.id"
      class="ui-selector__element"
      :class="{ 'ui-selector__element_selected': item.id === selectedId }"
      @click="$emit('select', item.id)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelector',

  model: {
    prop: 'selectedId',
    event: 'select',
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedId: {
      type: Number,
      required: true,
    },
  },
};
</script>
