<template>
  <div
    v-if="!userLoading"
    class="home"
    :class="{
      'home_prestep': isPrestep,
      'home_multipay': isMultiPayPage,
      'home_frp-mode': isFullReceiptMode,
    }"
  >
    <template v-if="!status">
      <div class="home__header">
        <template v-if="isFullReceiptMode">
          <img
            src="@/assets/logo-vertical.svg"
            class="home__logo"
          />
        </template>

        <template v-else>
          <user-avatar
            v-if="isPrestep"
            :payout-id="locationPayoutId"
            class="home__prestep-avatar"
            size="small"
          />
          <img
            v-else
            src="@/assets/logo-vertical.svg"
            class="home__logo"
          />
        </template>

        <ui-locale
          class="home__locale"
          @update:locale="onLocaleChange"
        />
      </div>

      <div class="home__body">
        <template v-if="isFullReceiptMode">
          <user-avatar
            :payout-id="locationPayoutId"
            class="home__user-avatar"
          />

          <p v-if="location.name" class="home__user-location">
            {{ location.name }}
          </p>

          <ui-staff-list
            v-model="receiptTipsManualy"
            :staff="receiptStaffList"
            :currency="currency"
            :vat="vat"
            class="home__staff-list"
          >
            <user-amount
              :value.sync="receiptCommon.tipsSum"
              :feeChecked.sync="receiptCommon.feeChecked"
              :feeAmount.sync="receiptCommon.fee"
              :currency="currency"
              :payout-id="locationPayoutId"
              :api-version="4"
              :disabled="receiptTipsManualy"
              :min-tips-amount="minTipsAmount"
              label="Tips:"
              hide-fees
              class="home__user-amount"
              @fee-opened="onFeeOpened"
              @update:error="onAmountError"
              @update:value="(amount) => receiptCommonInput(amount)"
              @update:typing="onTypingChanged"
            />

            <ui-tips
              :ab-variant="abVariant"
              :tips="receiptCommonTips"
              :currency="currency"
              :selected-tip-id="receiptCommon.selectedTip.id"
              :disabled="receiptTipsManualy"
              class="home__ui-tips"
              @select="receiptSelectCommonTip"
            />

            <user-amount
              :value="receiptCommon.tipsSum"
              :feeChecked.sync="receiptCommon.feeChecked"
              :feeAmount.sync="receiptCommon.fee"
              :currency="currency"
              :payout-id="locationPayoutId"
              :api-version="4"
              :disabled="receiptTipsManualy"
              :min-tips-amount="minTipsAmount"
              hide-input
              class="home__user-amount"
              @fee-opened="onFeeOpened"
              @update:error="onAmountError"
              @update:feeLoading="onFeeLoadingChanged"
            />
          </ui-staff-list>

          <template v-if="receiptTipsManualy">
            <ui-card
              v-for="item in receiptSeparate.staff"
              :key="`staff-${item.payoutId}-${item.service}`"
            >
              <user-staff
                class="user-staff_inner"
                :staff="item"
                labeled
                receipt
              >
                <user-amount
                  :value.sync="item.tips"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :api-version="4"
                  :min-tips-amount="minTipsAmount"
                  label="Tips:"
                  hide-fees
                  class="home__user-amount"
                  @update:error="onAmountError"
                  @update:value="(amount) => receiptSeparateInput(amount, item)"
                  @update:typing="onTypingChanged"
                />
              </user-staff>
              <ui-tips
                :ab-variant="abVariant"
                :tips="receiptSeparateTips(item)"
                :currency="currency"
                :selected-tip-id="item.selectedTip.id"
                @select="receiptSelectSeparateTip($event, item)"
              />
            </ui-card>
          </template>

          <ui-card>
            <template v-if="showFeedbackBlock">
              <user-rates
                v-model="form.rate"
                class="home__user-rates"
                @input="onRateChanged"
              />
              <template v-if="showFeedbackReviewsAndMessage">
                <user-reviews
                  v-if="goodRates.length > 0"
                  v-model="form.reviews"
                  :rate="form.rate"
                  :reviews="location.reviews"
                  :location-type="location.type"
                  :country-iso="countryIso"
                  class="home__user-reviews"
                  @input="onReviewsChanged"
                />
                <ui-input
                  tag="textarea"
                  v-model="form.comment"
                  :placeholder="$t('form.commentPlaceholder')"
                  class="home__user-comment"
                  maxlength="250"
                  rows="1"
                  small
                />
              </template>
            </template>

            <template v-if="showReceiptAmountFee">
              <template v-if="receiptTipsManualy && receiptSeparateTotal > 0">
                <div class="home__total-price">
                  {{ $t('form.total') }}: <ui-money :amount="receiptSeparateTotal" :currency="currency" />
                </div>

                <user-amount
                  :value="receiptSeparate.tipsSum"
                  :feeChecked.sync="receiptSeparate.feeChecked"
                  :feeAmount.sync="receiptSeparate.fee"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :api-version="4"
                  :min-tips-amount="minTipsAmount"
                  hide-input
                  class="home__user-amount"
                  @fee-opened="onFeeOpened"
                  @update:error="onAmountError"
                  @update:feeLoading="onFeeLoadingChanged"
                />
              </template>

              <template v-else-if="!receiptTipsManualy && receiptCommonTotal > 0">
                <div class="home__total-price">
                  {{ $t('form.total') }}: <ui-money :amount="receiptCommonTotal" :currency="currency" />
                </div>
              </template>
            </template>

            <template v-if="form.rate > 0">
              <div class="home__user-buttons">
                <ui-button
                  v-if="(!receiptTipsManualy && receiptCommon.tipsSum > 0) || (receiptTipsManualy && receiptSeparate.tipsSum > 0)"
                >
                  {{ $t('form.button.payCard') }}
                </ui-button>
                <ui-button
                  v-else
                >
                  {{ $t('form.button.onlyFeedback') }}
                </ui-button>
              </div>
            </template>
          </ui-card>

          <UserAgreeCheckbox
            v-model="form.agree"
            :payout-id="localPayoutId"
            :is-staff="isStaff"
            @input="onAgreeChanged"
          />
        </template>

        <template v-else>

          <!-- Prestep sub location selection -->
          <template v-if="isMasterLocation">
            <div
              class="home__search-title"
              v-html="groupTitle"
            />

            <router-link
              v-for="item in filteredLocations"
              :key="item.id"
              :to="item.link"
              class="home__search-sub-locations"
              @click.native="onSubLocationOpened(item)"
            >
              <sub-location
                :name="item.name"
                :location-type="item.subLocationType"
              />
            </router-link>
          </template>

          <!-- Prestep group selection -->
          <template v-else-if="isGroupList">
            <div
              class="home__search-title"
              v-html="groupTitle"
            />

            <router-link
              v-for="item in filteredGroups"
              :key="item.id"
              :to="item.link"
              class="home__search-groups"
              @click.native="onGroupOpened(item)"
            >
              <location-group
                :group="item"
                :location-type="location.type"
              />
            </router-link>
          </template>

          <!-- Prestep staff selection -->
          <template v-else-if="isStaffList">
            <div
              class="home__search-title"
              v-html="$t('search.staffsTitle')"
            />

            <ui-switcher
              v-if="showMultiTipsSwitcher"
              v-model="selectMultipleSpecialists"
              :label="$t('search.multipleSpecialistLabel')"
              class="home__multiple-switcher"
            />

            <ui-search
              v-model="filter"
              :placeholder="$t('search.inputPlaceholder')"
              class="home__search-filter"
            />

            <template v-if="selectMultipleSpecialists">
              <user-staff
                v-for="item in filteredStaffs"
                :key="item.payoutId"
                :selected="isUserSelectedById(item.payoutId)"
                class="home__search-staff"
                :staff="item"
                selectable
                @click.native="selectSpecialist({ staff: item })"
              />
            </template>
            <template v-else>
              <router-link
                v-for="item in filteredStaffs"
                :key="item.payoutId"
                :to="item.link"
                class="home__search-staff"
                @click.native="onStaffOpened(item)"
              >
                <user-staff
                  :staff="item"
                />
              </router-link>
            </template>
          </template>

          <!-- Staff or location tips form -->
          <template v-else>
            <template v-if="isMultiPayPage">
              <user-avatar
                :payout-id="locationPayoutId"
                class="home__user-avatar"
              />

              <div v-if="showBackButton || staffNames" class="home__user-name-row">
                <ui-icon-button
                  v-if="showBackButton"
                  name="left-arrow"
                  size="28px"
                  class="home__back-btn"
                  @click="goToLocationMenu"
                />
                <h1 v-if="staffNames" class="home__user-name">
                  {{ staffNames }}
                </h1>
              </div>

              <p v-if="location.name" class="home__user-location">
                {{ location.name }}
              </p>

              <TipSelectorCard class="home__tip-selector">
                <ui-tips
                  :ab-variant="abVariant"
                  :tips="tips"
                  :currency="currency"
                  :selected-tip-id="selectedTip.id"
                  @select="selectTipFromSelector"
                />
              </TipSelectorCard>

              <ui-card v-for="staffId in staffPayoutId" :key="staffId">
                <user-staff
                  class="user-staff_inner"
                  :staff="getStaffById(staffId)"
                  multipay
                >
                  <!--  Multipay   -->
                  <user-amount
                    :value="form.amount[staffId]"
                    :feeChecked.sync="form.feeChecked"
                    :currency="currency"
                    :payout-id="locationPayoutId"
                    hide-fees
                    :api-version="isCheckoutPayment ? 5 : 4"
                    :min-tips-amount="minTipsAmount"
                    show-error-anyway
                    class="home__user-amount"
                    @fee-opened="onFeeOpened"
                    @update:feeChecked="onFeeCheckedChanged"
                    @update:error="onAmountError"
                    @update:value="(amount) => updateAmount(amount, staffId)"
                    @update:typing="onTypingChanged"
                  />
                </user-staff>
              </ui-card>
              <ui-card>
                <div v-if="bill" class="home__user-bill">
                  {{ $t('form.amountLabel') }}: <ui-money :amount="bill" :currency="currency" no-style />
                </div>
                <template v-if="showFeedbackBlock">
                  <user-rates
                    v-model="form.rate"
                    class="home__user-rates"
                    @input="onRateChanged"
                  />
                  <template v-if="showFeedbackReviewsAndMessage">
                    <user-reviews
                      v-if="goodRates.length > 0"
                      v-model="form.reviews"
                      :rate="form.rate"
                      :reviews="location.reviews"
                      :location-type="location.type"
                      :country-iso="countryIso"
                      class="home__user-reviews"
                      @input="onReviewsChanged"
                    />
                    <validation-provider
                      tag="div"
                      rules="min:15"
                      v-slot="{ errors }"
                    >
                      <ui-input
                        tag="textarea"
                        v-model="form.comment"
                        :placeholder="$t('form.commentPlaceholder')"
                        :error="errors[0]"
                        class="home__user-comment"
                        maxlength="250"
                        rows="1"
                        small
                      />
                    </validation-provider>
                  </template>
                </template>

                <user-amount
                  :value="form.amountSum"
                  :feeChecked="form.feeChecked"
                  :feeAmount.sync="form.feeAmountSum"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  hide-input
                  :api-version="isCheckoutPayment ? 5 : 4"
                  :min-tips-amount="minTipsAmount"
                  :class="{ 'home__user-amount': showAmountFees }"
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="(value) => onFeeCheckedChangedHandler(value)"
                  @update:error="onAmountError"
                  @update:feeLoading="onFeeLoadingChanged"
                />
                <div v-if="showAmountFees" class="home__total-price">
                  {{ $t('form.total') }}: <ui-money :amount="totalSum" :currency="currency" />
                </div>

                <template v-if="showSubmit">
                  <div class="home__user-buttons">
                    <ui-button
                      v-if="onlyFeedback"
                      :is-loading="feedbackLoading"
                      :disabled="isDisabled || feedbackSent"
                      @click.prevent="`
                        ${ isCheckoutPayment
                          ? handleSubmit('submitCheckoutFeedback')
                          : handleSubmit('submitFeedback')
                        }
                      `"
                    >
                      {{ $t('form.button.onlyFeedback') }}
                    </ui-button>
                    <template v-else>
                      <checkout-by-pay
                        v-if="isCheckoutPayment"
                        :name="name"
                        :is-staff="isStaff"
                        :amplitude-payment-data="amplitudePaymentData"
                        :list-payment="checkoutPayData.listPayment"
                        :fee-checked="checkoutPayData.feeChecked"
                        :currency="checkoutPayData.currency"
                        :amount="checkoutPayData.amount"
                        :fee-amount="checkoutPayData.feeAmount"
                        :group-id="checkoutPayData.groupId"
                        :workplace-payout-id="checkoutPayData.workplacePayoutId"
                        :back-url="checkoutPayData.backUrl"
                        :disabled="isDisabled"
                        @submit="handleSubmit"
                      />
                      <ui-button
                        v-else-if="canPay"
                        :is-loading="payLoading"
                        :disabled="isDisabled"
                        :apple-pay="applePay"
                        :google-pay="googlePay"
                        @click.prevent="handleSubmit('submitPay')"
                      />

                      <ui-button
                        v-if="canGiroPay"
                        :is-loading="giroPayLoading"
                        :disabled="isDisabled"
                        giro-pay
                        @click.prevent="handleSubmit('submitGiroPay')"
                      />

                      <ui-button
                        v-if="canPayByCard"
                        :is-loading="cardLoading"
                        :disabled="isDisabled"
                        @click.prevent="`
                          ${ isCheckoutPayment
                            ? handleSubmit('submitCheckoutPayByCard')
                            : handleSubmit('submitPayByCard')
                          }
                        `"
                      >
                        {{ $t('form.button.payCard') }}
                      </ui-button>
                    </template>
                  </div>
                </template>
              </ui-card>
            </template>
            <template v-else>
              <user-avatar
                :payout-id="localPayoutId[0]"
                class="home__user-avatar"
              />

              <div v-if="showBackButton || nickName" class="home__user-name-row">
                <ui-icon-button
                  v-if="showBackButton"
                  name="left-arrow"
                  size="28px"
                  class="home__back-btn"
                  @click="goToLocationMenu"
                />
                <h1 v-if="nickName" class="home__user-name">
                  {{ nickName }}
                </h1>
              </div>

              <p v-if="isStaff && location.name" class="home__user-location">
                {{ location.name }}
              </p>

              <user-wish
                v-if="wish"
                :value="wish"
                class="home__user-wish"
              />

              <ui-card>
                <div v-if="bill" class="home__user-bill">
                  {{ $t('form.amountLabel') }}: <ui-money :amount="bill" :currency="currency" no-style />
                </div>

                <!--     Solopay       -->
                <user-amount
                  :value="form.amount[localPayoutId[0]]"
                  :feeChecked.sync="form.feeChecked"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :min-tips-amount="minTipsAmount"
                  hide-fees
                  class="home__user-amount"
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="onFeeCheckedChanged"
                  @update:error="onAmountError"
                  @update:value="(amount) => updateAmount(amount, localPayoutId[0])"
                  @update:typing="onTypingChanged"
                />
                <user-amount
                  :value="form.amountSum"
                  :feeChecked="form.feeChecked"
                  :feeAmount.sync="form.feeAmountSum"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :api-version="isCheckoutPayment ? 5 : 4"
                  :min-tips-amount="minTipsAmount"
                  hide-input
                  class="home__user-amount"
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="(value) => onFeeCheckedChangedHandler(value)"
                  @update:error="onAmountError"
                  @update:feeLoading="onFeeLoadingChanged"
                />
                <ui-tips
                  class="home__tips"
                  :ab-variant="abVariant"
                  :tips="tips"
                  :currency="currency"
                  :selected-tip-id="selectedTip.id"
                  @select="selectTipFromSelector"
                />
                <template v-if="showFeedbackBlock">
                  <user-rates
                    v-model="form.rate"
                    class="home__user-rates"
                    @input="onRateChanged"
                  />
                  <template v-if="showFeedbackReviewsAndMessage">
                    <user-reviews
                      v-if="goodRates.length"
                      v-model="form.reviews"
                      :rate="form.rate"
                      :reviews="location.reviews"
                      :location-type="location.type"
                      :country-iso="countryIso"
                      class="home__user-reviews"
                      @input="onReviewsChanged"
                    />
                    <validation-provider
                      tag="div"
                      rules="min:15"
                      v-slot="{ errors }"
                    >
                      <ui-input
                        tag="textarea"
                        v-model="form.comment"
                        :placeholder="$t('form.commentPlaceholder')"
                        :error="errors[0]"
                        class="home__user-comment"
                        maxlength="250"
                        rows="1"
                        small
                      />
                    </validation-provider>
                  </template>
                </template>

                <template v-if="showSubmit">
                  <div class="home__user-buttons">
                    <ui-button
                      v-if="onlyFeedback"
                      :is-loading="feedbackLoading"
                      :disabled="isDisabled || feedbackSent"
                      @click.prevent="`
                      ${ isCheckoutPayment
                          ? handleSubmit('submitCheckoutFeedback')
                          : handleSubmit('submitFeedback')
                        }
                      `"
                    >
                      {{ $t('form.button.onlyFeedback') }}
                    </ui-button>
                    <template v-else>
                      <checkout-by-pay
                        v-if="isCheckoutPayment"
                        :name="name"
                        :is-staff="isStaff"
                        :amplitude-payment-data="amplitudePaymentData"
                        :list-payment="checkoutPayData.listPayment"
                        :fee-checked="checkoutPayData.feeChecked"
                        :currency="checkoutPayData.currency"
                        :amount="checkoutPayData.amount"
                        :fee-amount="checkoutPayData.feeAmount"
                        :group-id="checkoutPayData.groupId"
                        :workplace-payout-id="checkoutPayData.workplacePayoutId"
                        :back-url="checkoutPayData.backUrl"
                        :disabled="isDisabled"
                        @submit="handleSubmit"
                      />
                      <ui-button
                        v-else-if="canPay"
                        :is-loading="payLoading"
                        :disabled="isDisabled"
                        :apple-pay="applePay"
                        :google-pay="googlePay"
                        @click.prevent="handleSubmit('submitPay')"
                      />

                      <ui-button
                        v-if="canGiroPay"
                        :is-loading="giroPayLoading"
                        :disabled="isDisabled"
                        giro-pay
                        @click.prevent="handleSubmit('submitGiroPay')"
                      />

                      <paypal
                        v-if="canPayPal"
                        :data="getPayPalPaymentData"
                        :is-staff="isStaff"
                        :amplitude-payment-data="amplitudePaymentData"
                        :disabled="isDisabled"
                        @submit="handleSubmit"
                      />

                      <ui-button
                        v-if="canPayByCard"
                        :is-loading="cardLoading"
                        :disabled="isDisabled"
                        @click.prevent="`
                          ${ isCheckoutPayment
                            ? handleSubmit('submitCheckoutPayByCard')
                            : handleSubmit('submitPayByCard')
                          }
                        `"
                      >
                        {{ $t('form.button.payCard') }}
                      </ui-button>
                    </template>
                  </div>
                </template>
              </ui-card>
            </template>

            <UserAgreeCheckbox
              v-model="form.agree"
              :payout-id="localPayoutId"
              :is-staff="isStaff"
              @input="onAgreeChanged"
            />
          </template>
        </template>
      </div>
      <AbsoluteFooter v-if="showMultipleSpecialistsActions">
        <MultipleActions
          @cancel="clearSelectedSpecialists"
          @continue="continueWithSelectedSpecialists"
        />
      </AbsoluteFooter>
    </template>

    <modal-status
      v-if="modalStatus"
      :status="modalStatus"
      :card-pay="cardPay"
      :rate="form.rate"
      :feedback-sent="feedbackSent"
      :is-loading="receiptLoading"
      :feedback-links="feedbackLinks"
      @closed="submitStatus"
      @toggle-receipt="toggleReceipt"
      @send-receipt="sendReceipt"
      @clicked-link="clickedLink"
    />

    <modal-sorry
      v-if="modalSorry"
      v-model="form.phoneNumber"
      :is-loading="sorryLoading"
      @receipt="receipted = true"
      @closed="submitSorry"
      @clicked-link="clickedLink"
    />

    <modal-receipt
      v-if="modalReceipt"
      @closed="submitStatus"
      @clicked-link="clickedLink"
    ></modal-receipt>

    <modal-submit-pay
      v-model="showSubmitPayModal"
      :total="submitMoney"
      @submit="submitPayInModal"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapState, mapGetters, mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js/pure';
import { merge } from 'lodash';
import getFio from '@/utils/getFio';
import isFirstPageVisit from '@/utils/isFirstPageVisit';
import Money from '@/class/Money';
import api from '@/api';
import UiInput from '@/components/UiInput.vue';
import UiButton from '@/components/UiButton.vue';
import UiSearch from '@/components/UiSearch.vue';
import UiLocale from '@/components/UiLocale.vue';
import UiCard from '@/components/UiCard.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import TipSelectorCard from '@/components/TipSelectorCard.vue';
import UserAmount from '@/components/UserAmount.vue';
import UserRates from '@/components/UserRates.vue';
import UserReviews from '@/components/UserReviews.vue';
import UserStaff from '@/components/UserStaff.vue';
import LocationGroup from '@/components/LocationGroup.vue';
import SubLocation from '@/components/SubLocation.vue';
import ModalStatus from '@/components/ModalStatus.vue';
import ModalSorry from '@/components/ModalSorry.vue';
import ModalReceipt from '@/components/ModalReceipt.vue';
import UiSwitcher from '@/components/UiSwitcher.vue';
import resetMixin from '@/mixins/reset-mixin';
import UiTips from '@/components/UiTips.vue';
import MultipleActions from '@/components/MultipleActions.vue';
import AbsoluteFooter from '@/components/AbsoluteFooter.vue';
import UserAgreeCheckbox from '@/components/UserAgreeCheckbox.vue';
import UserWish from '@/components/UserWish.vue';
import UiStaffList from '@/components/UiStaffList.vue';
import CheckoutByPay from '@/components/CheckoutByPay.vue';
import Paypal from '@/components/Paypal.vue';
import UiMoney from '@/components/UiMoney.vue';
import ModalSubmitPay from '@/components/ModalSubmitPay.vue';
import { checkTransactionCollision } from '@/utils/transactionCollisions';
import UiIconButton from '@/components/UiIconButton.vue';
import multiTipsModes from '@/config/multiTipsModes';

export default {

  metaInfo() {
    const canonicalUrlParams = {
      name: 'home',
      params: {
        locationPayoutId: this.locationPayoutId,
      },
    };

    if (this.staffPayoutId.length === 1 /* single staff */) {
      canonicalUrlParams.query = {
        staffId: this.staffPayoutId,
      };
    }

    const canonicalUrl = window.location.origin + this.$router.resolve(canonicalUrlParams)?.href;

    return {
      title: `Tips for ${this.name}`,
      meta: [
        {
          name: 'description',
          content: this.staffPayoutId.length && this.location.name
            ? `${this.name} works in ${this.location.name} and receives tips with EasyTip.`
            : `Tips for ${this.name}. Cashless tips for employees by QR code.`,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: canonicalUrl,
        },
      ],
    };
  },

  mixins: [
    resetMixin(function initialState() {
      return {
        paymentIntentId: '',
        feedbackLoading: false,
        feedbackSent: false,
        cardLoading: false,
        payLoading: false,
        giroPayLoading: false,
        sorryLoading: false,
        receiptLoading: false,
        feeLoading: false,
        tipsFeeLoading: false,
        typing: false,
        modalStatus: null,
        modalReceipt: null,
        modalSorry: null,
        filter: '',
        form: {
          amount: {},
          feeAmount: {},
          feeChecked: true,
          amountSum: 0,
          feeAmountSum: 0,
          rate: this.rate,
          reviews: [],
          comment: '',
          agree: true,
          phoneNumber: '',
        },
        feeRequest: null,
        stripe: null,
        stripeLoading: false,
        stripePaymentRequest: null,
        applePay: false,
        googlePay: false,
        receipted: false,
        cardPay: false,
        amountError: '',
        selectMultipleSpecialists: false,
        selectedSpecialists: [],
        selectedTip: {},
        receiptTipsManualy: false,
        receiptCommon: {
          staff: null,
          selectedTip: {},
          feeChecked: true,
          fee: 0,
          tipsSum: 0,
          totalSum: 0,
        },
        receiptSeparate: {
          common: null,
          staff: null,
          feeChecked: true,
          fee: 0,
          tipsSum: 0,
          totalSum: 0,
        },
        feedbackId: null,
        showSubmitPayModal: false,
        abortedWithSubmitMethod: null,
      };
    }),
  ],

  components: {
    UiIconButton,
    ModalSubmitPay,
    UserAgreeCheckbox,
    UiTips,
    AbsoluteFooter,
    MultipleActions,
    UiSwitcher,
    UiInput,
    UiButton,
    UiSearch,
    UiLocale,
    UiCard,
    TipSelectorCard,
    UserAvatar,
    UserAmount,
    UserRates,
    UserReviews,
    UserStaff,
    LocationGroup,
    SubLocation,
    ModalStatus,
    ModalSorry,
    ModalReceipt,
    UserWish,
    UiStaffList,
    ValidationProvider,
    CheckoutByPay,
    Paypal,
    UiMoney,
  },

  props: {
    locationPayoutId: {
      type: String,
      required: true,
    },
    staffPayoutId: {
      type: Array,
      default: () => [],
    },
    groupPayoutId: String,
    bill: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    feeAmount: {
      type: Number,
      default: 0,
    },
    feeChecked: {
      type: Boolean,
      default: true,
    },
    rate: {
      type: Number,
      default: 0,
    },
    isFrpMode: {
      type: Boolean,
      default: false,
    },
    preview: Boolean,
    status: String,
    sessionId: String,
    vat: Number,
    queryStaffList: Array,
    paymentIntent: String,
    paymentIntentClientSecret: String,
    checkoutPaymentId: String,
    checkoutPayment: Boolean,
    isApplyCheckoutPayment: Boolean,
    isGoogleCheckoutPayment: Boolean,
    isCardCheckoutPayment: Boolean,
    payPalCkoSessionId: String,
    payPalPaymentId: String,
    payPalPayment: Boolean,
  },

  computed: {
    ...mapState('user', {
      userLoading: 'isLoading',
    }),
    ...mapState('user', [
      'publicKey',
      'location',
      'group',
      'staff',
    ]),
    ...mapGetters('user', [
      'isStaff',
      'isLocation',
      'isGroup',
      'isGroupList',
      'isMasterLocation',
      'isStaffList',
      'isIndividual',
      'isCommonSimple',
      'isCommonAdvanced',
      'hasKYC',
      'hasGroups',
      'hasList',
      'wish',
      'name',
      'nickName',
      'currency',
      'countryIso',
      'minTipsAmount',
    ]),
    locationPayButtons() {
      return this.location.payButtons?.amountList;
    },
    showBackButton() {
      return this.location.isOnlineMenuEnabled;
    },
    localPayoutId() {
      return this.staffPayoutId.length
        ? this.staffPayoutId
        : [this.locationPayoutId];
    },
    isMultiPayPage() {
      return this.staffPayoutId.length > 1;
    },
    multiplePayEventsInfo() {
      return {
        multipleTips: this.isMultiPayPage || this.selectMultipleSpecialists,
      };
    },
    apiVersion() {
      return this.isMultiPayPage ? 4 : 3;
    },
    showMultipleSpecialistsActions() {
      return this.selectMultipleSpecialists && this.selectedSpecialists.length && this.isStaffList;
    },
    staffNames() {
      const staffNames = [];

      if (this.staffPayoutId) {
        this.staffPayoutId.forEach((staffId) => {
          const staff = this.getStaffById(staffId);
          if (staff && (staff.firstName || staff.nickName)) {
            staffNames.push(staff.nickName || staff.firstName);
          }
        });
      }

      return staffNames.join(', ');
    },
    totalSum() {
      return (this.form.amountSum + (this.form.feeChecked ? this.form.feeAmountSum : 0)).toFixed(2);
    },
    tips() {
      // mapGetters in methods dont works.
      return this.$store.getters['user/tips'](this.bill);
    },
    isDebug() {
      return this.$route.query?.debug || false;
    },
    isPrestep() {
      return this.isGroupList || this.isStaffList || this.isMasterLocation;
    },
    incorrectAmount() {
      const amounts = Object.values(this.form.amount) || [];
      const eachAmountCorrect = amounts.every((amount) => !amount || amount >= this.minTipsAmount);

      return !eachAmountCorrect || this.amountError;
    },
    showAmountFees() {
      return !this.incorrectAmount && this.form.amountSum > 0;
    },
    showSubmit() {
      if (this.incorrectAmount) {
        return false;
      }

      if (this.form.rate > 0) {
        return true;
      }

      return this.form.amountSum > 0;
    },
    onlyFeedback() {
      return this.form.amountSum <= 0 && this.form.rate > 0;
    },
    someLoading() {
      return this.stripeLoading
        || this.feedbackLoading
        || this.cardLoading
        || this.payLoading
        || this.receiptLoading
        || this.giroPayLoading
        || this.feeLoading
        || this.tipsFeeLoading
        || this.typing;
    },
    goodRates() {
      return this.location.reviews.filter(({ status, type }) => {
        return status === 'ACTIVE' && type === 'COMPLIMENT';
      });
    },
    isDisabled() {
      if (
        this.form.rate > 0
        && this.form.rate < 4
        && this.form.reviews.length === 0
        && this.goodRates.length
      ) {
        return true;
      }

      return (!this.stripe && !this.isCheckoutPayment)
        || this.someLoading
        || !this.form.agree;
    },
    canGooglePay() {
      return this.googlePay && this.location.paymentMethodsAllowed.includes('GOOGLE_PAY');
    },
    canApplePay() {
      return this.applePay && this.location.paymentMethodsAllowed.includes('APPLE_PAY');
    },
    canPay() {
      return this.canApplePay || this.canGooglePay;
    },
    canGiroPay() {
      if (this.isIndividual) {
        return this.staff.paymentMethodsAllowed.includes('GIRO_PAY');
      }

      if (this.isCommonSimple || this.isCommonAdvanced) {
        return this.location.paymentMethodsAllowed.includes('GIRO_PAY');
      }

      return false;
    },
    canPayByCard() {
      return this.location.paymentMethodsAllowed.includes('BY_CARD');
    },
    canPayPal() {
      if (this.isIndividual) {
        return this.staff.paymentMethodsAllowed.includes('PAY_PAL');
      }

      if (this.isCommonSimple || this.isCommonAdvanced) {
        return this.location.paymentMethodsAllowed.includes('PAY_PAL');
      }

      return false;
    },
    stripePaymentData() {
      const totalAmount = +(this.form.amountSum + (this.form.feeChecked ? this.form.feeAmountSum : 0)).toFixed(2);

      return {
        country: 'US', // this.countryIso.toUpperCase(),
        currency: this.currency.iso,
        total: {
          label: `Tip for ${this.name}`,

          // The amount in the currency's subunit (e.g. cents, yen, etc.)
          // @see {@link https://stripe.com/docs/js/appendix/payment_item_object#payment_item_object-amount}
          amount: Math.round(totalAmount * 100),
        },
      };
    },
    amplitudePaymentData() {
      const data = {
        ...(this.getApiPaymentData()),
        payoutId: this.localPayoutId.join(', '),
        country: this.countryIso,
        ...this.multiplePayEventsInfo,
      };

      if (!this.location.showReviewsModule) {
        return data;
      }

      const {
        stars,
        cards,
        ...other
      } = data;

      return {
        ...other,
        rate: stars,
        reviews: this.reviewsMap,
      };
    },
    successUrl() {
      return this.getStatusUrl('success');
    },
    errorUrl() {
      return this.getStatusUrl('error');
    },
    giroPayUrl() {
      return this.getStatusUrl('giroPay');
    },
    reviewsMap() {
      return this.form.reviews.reduce((carry, { id, name }) => {
        return {
          ...carry,
          [id]: name,
        };
      }, {});
    },
    reviewsNames() {
      return this.form.reviews.map(({ name }) => name);
    },
    showModalSorry() {
      return this.form.rate > 0 && this.form.rate < 4;
    },
    payoutKey() {
      return [
        this.locationPayoutId,
        ...this.staffPayoutId,
        this.groupPayoutId,
      ].filter((v) => v).join('-');
    },
    filteredStaffs() {
      const staffList = this.isGroup
        ? this.group.staffs
        : this.location.staffs;

      const query = this.isGroup
        ? { groupId: this.groupPayoutId }
        : null;

      return staffList
        .filter((item) => {
          return `${item.nickName || getFio(item)}`.toLowerCase().indexOf(
            this.filter.toLowerCase(),
          ) > -1 && item.payoutId;
        })
        .map((item) => ({
          ...item,
          link: {
            params: {
              locationPayoutId: this.locationPayoutId,
            },
            query: {
              ...this.$route.query,
              ...query,
              staffId: item.payoutId,
            },
          },
        }));
    },
    filteredLocations() {
      return this.location.subLocations
        .filter((item) => item.payoutId)
        .map((item) => ({
          ...item,
          link: {
            params: {
              locationPayoutId: item.payoutId,
            },
          },
        }));
    },
    filteredGroups() {
      return this.location.groups
        .filter((item) => item.staffs.length && item.payoutId)
        .map(({ url, ...item }) => ({
          ...item,
          link: url ? {
            path: url,
            query: {
              ...this.$route.query,
            },
          } : {
            params: {
              locationPayoutId: this.locationPayoutId,
            },
            query: {
              ...this.$route.query,
              groupId: item.payoutId,
            },
          },
        }));
    },
    hasAggregateGroup() {
      // If path exists then group is fake reference to another restaurant (EASYTIP-3089).
      return this.filteredGroups.some(({ link }) => Boolean(link.path));
    },
    groupTitle() {
      if (this.hasAggregateGroup) {
        return this.$t('search.aggregateTitle');
      }

      return this.$t('search.groupTitle');
    },
    abVariant() {
      return this.location.abVariant;
    },
    typeOfReview() {
      return this.location.typeOfReview;
    },
    showFeedbackLinks() {
      const typesOfReviewForShowLinks = [
        'PUBLIC_REVIEWS_ONLY',
        'STANDARD_AND_PUBLIC_REVIEWS_ONLY',
      ];
      return typesOfReviewForShowLinks.includes(this.typeOfReview);
    },
    showFeedbackBlock() {
      return this.typeOfReview !== 'DISABLE_REVIEWS' && this.typeOfReview !== 'PUBLIC_REVIEWS_ONLY';
    },
    showFeedbackReviewsAndMessage() {
      if (this.typeOfReview === 'STANDARD_AND_PUBLIC_REVIEWS_ONLY' && this.form.rate > 3) {
        return false;
      }

      return this.form.rate;
    },
    feedbackLinks() {
      if (!this.showFeedbackLinks) {
        return {};
      }

      const attributes = [
        'googleMapsLink',
        'tripAdvisorLink',
        'trustPilotLink',
      ];

      return attributes.reduce((carry, attribute) => {
        const link = this.location[attribute];

        if (link) {
          carry = {
            ...carry,
            [attribute]: link,
          };
        }

        return carry;
      }, {});
    },
    isFullReceiptMode() {
      return this.isFrpMode
        && this.location.type === 'BEAUTY_SALON';
    },
    showReceiptAmountFee() {
      return !this.incorrectAmount
        && (this.receiptCommon.totalSum > 0 || this.receiptSeparate.totalSum > 0);
    },
    receiptSeparateTotal: {
      get() {
        return +(this.receiptSeparate.totalSum + (this.receiptSeparate.feeChecked ? this.receiptSeparate.fee : 0)).toFixed(2);
      },

      set(value) {
        this.receiptSeparate.totalSum = value;
      },
    },
    receiptCommonTips() {
      // mapGetters in methods dont works.
      return this.$store.getters['user/tips'](this.receiptCommonSumField('amount'), true);
    },
    receiptCommonTotal: {
      get() {
        return +(this.receiptCommon.totalSum + (this.receiptCommon.feeChecked ? this.receiptCommon.fee : 0)).toFixed(2);
      },

      set(value) {
        this.receiptCommon.totalSum = value;
      },
    },
    receiptStaffList() {
      return this.receiptTipsManualy
        ? this.receiptSeparate.common
        : this.receiptCommon.staff;
    },
    isCheckoutPayment() {
      const checkoutTypes = ['CHECKOUT_UAE', 'CHECKOUT', 'CHECKOUT_UK_DASHBOARD'];

      if (this.isIndividual) {
        return checkoutTypes.includes(this.staff.paymentSystem);
      }

      if (this.isCommonSimple || this.isCommonAdvanced) {
        return checkoutTypes.includes(this.location.paymentSystem);
      }

      return false;
    },
    checkoutPayData() {
      const {
        feeChecked,
        currency,
        amount,
        feeAmount,
        groupId,
        workplacePayoutId,
        listPayment = [],
        ...data
      } = this.getApiPaymentData();

      let tempListPayment = listPayment;

      if (tempListPayment.length === 0) {
        tempListPayment = [{
          payoutId: data.payoutId,
          comment: data.comment,
          stars: data.stars,
          cards: data.cards,
          amount,
        }];
      }

      return {
        listPayment: tempListPayment,
        feeChecked,
        currency,
        amount,
        feeAmount,
        groupId,
        workplacePayoutId,
        backUrl: {
          rate: this.form.rate,
          locationId: this.$route.params.locationPayoutId,
          query: this.$route.query,
        },
      };
    },
    getPayPalPaymentData() {
      return {
        ...this.getApiPaymentData(),
        rate: this.form.rate,
        locationId: this.locationPayoutId,
        payPalPayment: this.canPayPal,
      };
    },
    billMoney() {
      return new Money(this.bill || 0, this.currency).toString();
    },
    submitMoney() {
      return new Money(this.form.amountSum, this.currency).toString();
    },
    showMultiTipsSwitcher() {
      return this.location.multiTipsMode === multiTipsModes.ON
        || this.location.multiTipsMode === multiTipsModes.DEFAULT_ON;
    },
  },

  watch: {
    payoutKey: {
      immediate: true,
      async handler() {
        const {
          locationPayoutId,
          staffPayoutId,
          groupPayoutId,
          localPayoutId,
          bill,
          checkoutPaymentId,
          payPalPaymentId,
          payPalCkoSessionId,
        } = this;

        const amplitudeParams = {
          bill,
          workplacePayoutId: locationPayoutId,
          pathname: window.location.pathname,
          ...this.multiplePayEventsInfo,
        };

        try {
          await this.loadUser({
            locationPayoutId,
            staffPayoutId: staffPayoutId[0],
            groupPayoutId,
          });

          const firstVisitChecked = sessionStorage.getItem('firstVisitChecked');
          if (this.location.isOnlineMenuEnabled && isFirstPageVisit() && !firstVisitChecked && !bill) {
            await this.$router.push({ name: 'location' });
          }

          amplitudeParams.country = this.countryIso;

          this.$amplitude.event('PAGE_VIEW', {
            ...amplitudeParams,
            hostname: window.location.hostname,
            url: window.location.href,
          });

          if (!this.status) {
            if (this.isGroupList) {
              this.$amplitude.event('PAGE_OPEN_GROUP_LIST', {
                ...amplitudeParams,
                groupPayoutId,
              });
            } else if (this.isStaffList) {
              this.$amplitude.event('PAGE_OPEN_STAFF_LIST', amplitudeParams);
            } else if (this.isLocation) {
              this.$amplitude.event('PAGE_OPEN_LOCATION', amplitudeParams);
            } else if (this.isStaff) {
              this.$amplitude.event('PAGE_OPEN_STAFF', {
                ...amplitudeParams,
                payoutId: localPayoutId.join(', '),
              });
            }
          }
        } catch (e) {
          this.$amplitude.event('PAGE_WRONG', {
            pathname: window.location.pathname,
          });

          this.$router.push({ name: 'fallback' });

          return;
        }

        let checkout;
        if (this?.checkoutPayment) {
          checkout = this.checkoutPayment;
        } else {
          checkout = this.isCheckoutPayment;
        }

        const amplitudePaymentData = {
          payoutId: localPayoutId.join(', '),
          workplacePayoutId: locationPayoutId,
          amount: this.amount,
          feeAmount: this.feeAmount,
          feeChecked: this.feeChecked,
          sessionId: this.sessionId,
          country: this.countryIso,
        };

        if (checkout) {
          if (this.status) {
            if (this.showModalSorry) {
              this.modalSorry = () => {
                return this.sendCheckoutPaymentFeedbackInfo({ checkoutPaymentId });
              };
            } else {
              if (this.status === 'error') {
                this.$amplitude.eventOnce(
                  `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAGE_OPEN_ERROR`,
                  amplitudePaymentData,
                  checkoutPaymentId,
                );

                if (this.isApplyCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_APPLE_PAY_ERROR`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isGoogleCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_GOOGLE_PAY_ERROR`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isCardCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_PAY_BY_CARD_FAILED`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                this.modalStatus = 'error';
              }

              if (this.status === 'success') {
                this.$amplitude.eventOnce(
                  `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAGE_OPEN_SUCCESS`,
                  amplitudePaymentData,
                  checkoutPaymentId,
                );

                if (this.isApplyCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_APPLE_PAY_SUCCESS`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isGoogleCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_GOOGLE_PAY_SUCCESS`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isCardCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_PAY_BY_CARD_SUCCESS`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                this.modalStatus = 'success';
              }
            }
          }
        } else if (this.payPalPayment) {
          if (this.status) {
            await this.checkPayPalPayment({
              status: this.status,
              paymentId: payPalPaymentId,
              ckoSessionId: payPalCkoSessionId,
            });

            if (this.showModalSorry) {
              this.modalSorry = () => {
                return this.sendCheckoutPaymentFeedbackInfo({
                  checkoutPaymentId: payPalPaymentId,
                });
              };
            } else {
              if (this.status === 'error') {
                this.$amplitude.eventOnce(
                  `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAYPAL_PAY_ERROR`,
                  amplitudePaymentData,
                  payPalCkoSessionId,
                );

                this.modalStatus = 'error';
              }

              if (this.status === 'success') {
                this.$amplitude.eventOnce(
                  `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAYPAL_PAY_SUCCESS`,
                  amplitudePaymentData,
                  payPalCkoSessionId,
                );

                this.modalStatus = 'success';
              }
            }
          }
        } else {
          await this.loadStripe();

          if (this.status) {
            if (this.status === 'error') {
              this.modalStatus = 'error';
            }

            if (this.status === 'success') {
              this.$amplitude.eventOnce(
                `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAGE_OPEN_SUCCESS`,
                amplitudePaymentData,
                this.sessionId,
              );

              if (!this.showModalSorry) {
                this.modalStatus = this.status;
              }

              this.cardPay = true;

              try {
                const {
                  data: {
                    paymentIntentId,
                  },
                } = await api.getIntent(
                  {
                    sessionId: this.sessionId,
                    workplacePayoutId: this.locationPayoutId,
                  },
                  this.apiVersion,
                );

                if (paymentIntentId) {
                  this.$amplitude.eventOnce(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_SUCCESS`, {
                      paymentIntentId,
                      ...amplitudePaymentData,
                    },
                    paymentIntentId,
                  );

                  this.sendConfirm({ paymentIntentId });

                  if (this.showModalSorry) {
                    this.modalSorry = () => {
                      return this.sendPhone({ paymentIntentId });
                    };
                  }
                }
              } catch (e) {
                this.warn(e);
              }
            } else if (this.status === 'error') {
              this.$amplitude.eventOnce(
                `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAGE_OPEN_ERROR`,
                amplitudePaymentData,
                this.sessionId,
              );

              this.$amplitude.eventOnce(
                `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_FAILED`,
                amplitudePaymentData,
                this.sessionId,
              );
            }

            if (this.status === 'giroPay') {
              this.handleGiroPayStatus(amplitudePaymentData);
            }
          }
        }

        this.form.amount = {};
        this.form.feeAmount = {};

        const formAmount = this.preview ? this.amount || 0 : 0;

        if (this.preview && this.amount) {
          this.form.amountSum = this.amount;
        }

        this.localPayoutId.forEach((payoutId) => {
          this.form.amount = {
            ...this.form.amount,
            [payoutId]: formAmount,
          };
          if (!this.preview) {
            this.form.feeAmount = {
              ...this.form.feeAmount,
              [payoutId]: 0,
            };
          }
        });
        if (this.selectedTip.value) {
          this.selectTipFromSelector(this.selectedTip);
        }
      },
    },
    'form.rate': {
      handler() {
        this.form.reviews.splice(0);
      },
    },
    $route: {
      handler(newRoute) {
        if (!newRoute.query.staffId) {
          this.reset();
        }
      },
    },
    selectMultipleSpecialists: {
      handler(switcherStatus) {
        this.$amplitude.event('MULTIPLE_PAY_SWITCHER_CHANGED', switcherStatus);
      },
    },
    'form.feeAmountSum': {
      handler(feeAmount) {
        if (feeAmount) {
          this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_FEE_AMOUNT_CHANGE`, {
            payoutId: this.localPayoutId.join(', '),
            feeAmount,
            ...this.multiplePayEventsInfo,
          });
        }
      },
    },
    modalSorry: {
      handler(value) {
        if (value) {
          this.$amplitude.event(
            `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_SORRY_OPEN`,
            this.amplitudePaymentData,
          );
        }
      },
    },
    modalReceipt: {
      handler(value) {
        if (value) {
          this.$amplitude.event(
            `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_RECEIPT_OPEN`,
            this.amplitudePaymentData,
          );
        }
      },
    },
    modalStatus: {
      handler(value) {
        if (value) {
          this.$amplitude.event(
            `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_STATUS_OPEN`,
            this.amplitudePaymentData,
          );
        }
      },
    },
    receiptCommon: {
      deep: true,
      handler() {
        console.log('fee', this.receiptCommon.fee);
        console.log('tipsSum', this.receiptCommon.tipsSum);
        console.log('totalSum', this.receiptCommon.totalSum);
        console.log('------');
      },
    },
    receiptSeparate: {
      deep: true,
      handler() {
        console.log('-fee', this.receiptSeparate.fee);
        console.log('tipsSum', this.receiptSeparate.tipsSum);
        console.log('totalSum', this.receiptSeparate.totalSum);
        console.log('------');
      },
    },
    location: {
      immediate: true,
      handler(location) {
        if (location.multiTipsMode === multiTipsModes.DEFAULT_ON) {
          this.selectMultipleSpecialists = true;
        }
      },
    },
    userLoading: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.loadTipsFee();
        }
      },
    },
  },

  methods: {
    ...mapActions({
      loadUser: 'user/loadUser',
      loadLocationStaffs: 'user/loadLocationStaffs',
      calculateFee: 'fee/calculateFee',
    }),
    async loadTipsFee() {
      this.tipsFeeLoading = true;

      try {
        await Promise.allSettled(
          this.tips.map(({ value }) => {
            return this.calculateFee({
              payoutId: this.locationPayoutId,
              minTipsAmount: this.minTipsAmount,
              totalAmount: value * this.staffPayoutId.length,
              apiVersion: this.isCheckoutPayment ? 5 : 4,
            });
          }),
        );
      } finally {
        this.tipsFeeLoading = false;
      }
    },
    goToLocationMenu() {
      this.$router.push({ name: 'location' });
    },
    receiptCommonSumField(field) {
      return this.receiptCommon.staff.reduce((a, s) => +(s[field]) + a, 0);
    },
    receiptSelectCommonTip(tip) {
      this.receiptCommon.staff = this.receiptCommon.staff.map((item) => ({
        ...item,
        tips: +((parseFloat(tip.label) * item.amount) / 100).toFixed(2),
      }));

      this.receiptCommon.selectedTip = tip;
      this.receiptCommon.tipsSum = tip.value;
      this.receiptCommon.totalSum = this.receiptCommonSumField('amount') + this.receiptCommon.tipsSum;
      this.receiptCommonTotal = this.receiptCommon.totalSum;
    },
    receiptCommonInput(amount) {
      const amountSum = this.receiptCommonSumField('amount');

      this.receiptCommon.staff.forEach((item, index, arr) => {
        arr[index] = {
          ...item,
          tips: +((parseFloat(item.amount / amountSum) * amount)).toFixed(2),
        };
      });

      this.receiptCommon.selectedTip = {};
      this.receiptCommon.totalSum = amountSum + this.receiptCommon.tipsSum;
      this.receiptCommonTotal = this.receiptCommon.totalSum;
    },
    receiptSeparateSumField(field) {
      return this.receiptSeparate.staff.reduce((a, s) => +(s[field]) + a, 0);
    },
    receiptSeparateTips(item) {
      return this.$store.getters['user/tips'](item.amount, true);
    },
    receiptSelectSeparateTip(tip, staff) {
      this.receiptSeparate.staff.forEach((item) => {
        if (staff.payoutId === item.payoutId) {
          item.tips = +((parseFloat(tip.label) * item.amount) / 100).toFixed(2);
          item.selectedTip = tip;
        }
      });

      this.receiptSeparate.common.forEach((item) => {
        if (staff.payoutId === item.payoutId) {
          item.tips = +((parseFloat(tip.label) * item.amount) / 100).toFixed(2);
        }
      });

      this.receiptSeparate.tipsSum = this.receiptSeparateSumField('tips');
      this.receiptSeparate.totalSum = this.receiptSeparateSumField('amount') + this.receiptSeparate.tipsSum;
      this.receiptSeparateTotal = this.receiptSeparate.totalSum;
    },
    receiptSeparateInput(amount, item) {
      const staff = this.receiptSeparate.common.filter(({ payoutId }) => payoutId === item.payoutId);
      const staffDpl = staff.length > 1;

      this.receiptSeparate.common.forEach((s) => {
        if (s.payoutId === item.payoutId) {
          s.tips = staffDpl
            ? +((parseFloat(s.amount / item.amount) * amount)).toFixed(2)
            : amount;
          s.selectedTip = {};
        }
      });

      this.receiptSeparate.staff.forEach((s) => {
        if (s.payoutId === item.payoutId) {
          s.tips = staffDpl
            ? +((parseFloat(s.amount / item.amount) * amount)).toFixed(2)
            : amount;
          s.selectedTip = {};
        }
      });

      this.receiptSeparate.tipsSum = this.receiptSeparateSumField('tips');
      this.receiptSeparate.totalSum = this.receiptSeparateSumField('amount') + this.receiptSeparate.tipsSum;
      this.receiptSeparateTotal = this.receiptSeparate.totalSum;
    },
    getGiroPayPaymentData() {
      const {
        amount,
        currency,
        feeAmount,
        feeChecked,
        groupId,
        workplacePayoutId,
        listPayment,
      } = this.getApiPaymentData(true);

      return {
        amount,
        currency,
        feeAmount,
        feeChecked,
        groupId,
        workplacePayoutId,
        listPayment,
        paymentMethod: 'GIROPAY',
      };
    },
    getApiPaymentData(isMultiStaffData = false) {
      let data = {
        workplacePayoutId: this.locationPayoutId,
        groupId: this.groupPayoutId || undefined,
        currency: this.currency.iso,
        amount: this.form.amountSum,
        totalAmount: +(this.form.amountSum + (this.form.feeChecked ? this.form.feeAmountSum : 0)).toFixed(2),
        feeAmount: this.form.feeAmountSum,
        feeChecked: this.form.feeChecked,
        phoneNumber: this.form.phoneNumber,
        kycProcessed: this.hasKYC,
      };

      if (this.isMultiPayPage || isMultiStaffData) {
        let staffList = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const elem in this.form.amount) {
          if (Object.prototype.hasOwnProperty.call(this.form.amount, elem)) {
            staffList.push({
              amount: this.form.amount[elem],
              payoutId: elem,
            });
          }
        }

        if (this.location.showReviewsModule) {
          staffList = staffList.map((elem) => ({
            comment: this.form.comment,
            stars: this.form.rate,
            cards: this.reviewsNames,
            ...elem,
          }));
        }

        // TODO: change structure when listFeedBack and listPayment will be different
        data = {
          ...data,
          listPayment: staffList.filter((staff) => staff.amount),
          listFeedback: staffList,
        };

        return data;
      }

      data = {
        ...data,
        payoutId: this.localPayoutId[0],
      };

      if (this.location.showReviewsModule) {
        data = {
          comment: this.form.comment,
          stars: this.form.rate,
          cards: this.reviewsNames,
          ...data,
        };
      }

      return data;
    },
    selectTipFromSelector(tip) {
      // eslint-disable-next-line no-restricted-syntax
      for (const elem in this.form.amount) {
        if (Object.prototype.hasOwnProperty.call(this.form.amount, elem)) {
          this.form.amount[elem] = tip.value;
        }
      }

      if (this.selectedTip.id !== tip.id) {
        this.selectedTip = tip;
        this.setActualAmount();
        this.onTipChanged(tip);
      }
    },
    updateAmount(amount, id) {
      this.selectedTip = {};
      this.form.amount[id] = amount;
      this.setActualAmount();
    },
    setActualAmount() {
      const amountSum = Object.values(this.form.amount).reduce((a, b) => a + b, 0);

      if (this.form.amountSum !== amountSum) {
        this.form.amountSum = amountSum;
        this.form.feeAmountSum = 0;
      }
    },
    onFeeCheckedChangedHandler(value) {
      this.form.feeChecked = value;
      this.onFeeCheckedChanged(value);
    },
    continueWithSelectedSpecialists() {
      this.$router.push({
        params: {
          locationPayoutId: this.locationPayoutId,
        },
        query: {
          staffId: this.selectedSpecialists.map((specialist) => specialist.payoutId),
        },
      });
      document.querySelector('body').scrollTop = 0;
    },
    clearSelectedSpecialists() {
      this.selectedSpecialists = [];
    },
    getStaffById(id) {
      return this.location?.staffs?.find((staff) => staff.payoutId === id) || null;
    },
    onFeeOpened() {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_FEE_OPEN`, {
        payoutId: this.localPayoutId.join(', '),
        ...this.multiplePayEventsInfo,
      });
    },
    onLocaleChange({ from, to }) {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_LOCALE_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        fromLocale: from,
        toLocale: to,
        ...this.multiplePayEventsInfo,
      });
    },
    onFeeCheckedChanged(feeChecked) {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_FEE_CHECKED_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        feeChecked,
        ...this.multiplePayEventsInfo,
      });
    },
    onTipChanged(tip) {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_TIP_PRESS`, {
        payoutId: this.localPayoutId.join(', '),
        tip,
        ...this.multiplePayEventsInfo,
      });
    },
    onAmountError(error) {
      if (error) {
        this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_AMOUNT_ERROR_INPUT`, {
          payoutId: this.localPayoutId.join(', '),
          error,
          ...this.multiplePayEventsInfo,
        });
      }

      this.amountError = error;
    },
    onRateChanged(rate) {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_RATE_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        rate,
        ...this.multiplePayEventsInfo,
      });
    },
    onReviewsChanged() {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_REVIEWS_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        reviews: this.reviewsMap,
        ...this.multiplePayEventsInfo,
      });
    },
    onAgreeChanged(agree) {
      this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_AGREE_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        agree,
        ...this.multiplePayEventsInfo,
      });
    },
    debug(value) {
      if (this.isDebug) {
        // eslint-disable-next-line no-alert
        alert(value);
      } else {
        console.log(value);
      }
    },
    warn(value) {
      if (this.isDebug) {
        // eslint-disable-next-line no-alert
        alert(value);
      }
    },
    async loadStripe() {
      this.stripeLoading = true;

      // @see {@link https://github.com/stripe/react-stripe-elements/issues/379}
      if (window.PaymentRequest) {
        const details = {
          total: {
            label: 'x',
            amount: {
              currency: 'GBP',
              value: '9.99',
            },
          },
        };
        // eslint-disable-next-line no-new
        new window.PaymentRequest([{ supportedMethods: ['basic-card'] }], details);
      }

      try {
        loadStripe.setLoadParameters({ advancedFraudSignals: false });
      } catch (e) {
        // You cannot change load parameters after calling loadStripe
      }

      try {
        this.stripe = await loadStripe(this.publicKey, {
          apiVersion: '2020-03-02',
        });
        this.stripePaymentRequest = this.stripe.paymentRequest(this.stripePaymentData);

        const result = await this.stripePaymentRequest.canMakePayment();

        if (!result) {
          return;
        }

        this.applePay = Boolean(result.applePay);
        this.googlePay = Boolean(result.googlePay);

        this.debug(JSON.stringify(result));
      } catch (e) {
        this.warn(e);
      } finally {
        this.stripeLoading = false;

        this.applePayButtonAmplitudeEvent(this.applePay, 'EXISTS', 'DO_NOT_EXIST');
        this.googlePayButtonAmplitudeEvent(this.googlePay, 'EXISTS', 'DO_NOT_EXIST');
        this.payByCardButtonAmplitudeEvent(this.stripe, 'EXISTS', 'DO_NOT_EXIST');
      }
    },
    getStatusUrl(status) {
      const {
        totalAmount,
        feeAmount,
        feeChecked,
        stars,
      } = this.getApiPaymentData();

      return window.location.origin + this.$router.resolve(
        this.getNestedRoute({
          params: {
            status,
          },
          query: {
            amount: totalAmount,
            feeAmount,
            feeChecked,
            rate: stars,
            backUrl: window.location.href,
          },
        }),
      )?.href;
    },
    getNestedRoute(nested) {
      const { name, params, query } = this.$route;

      return merge({}, {
        name,
        params,
        query,
      }, nested);
    },
    async sendConfirm(data) {
      try {
        await api.confirmPayment({
          success: true,
          ...data,
        }, this.apiVersion);
      } catch (e) {
        this.warn(e);
      }
    },
    async sendGiroPayConfirm(data) {
      try {
        await api.confirmGiroPayPayment({
          success: true,
          ...data,
        });
      } catch (e) {
        this.warn(e);
      }
    },
    toggleReceipt() {
      this.$amplitude.event(
        `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_TOGGLE_RECEIPT`,
        this.multiplePayEventsInfo,
      );
    },
    sendReceipt(email) {
      if (this.isCheckoutPayment) {
        this.sendCheckoutReceipt(email);
      } else if (this.payPalPayment) {
        this.sendPayPalReceipt(email);
      } else {
        this.sendStripeReceipt(email);
      }
    },
    async sendStripeReceipt(email) {
      if (!this.paymentIntentId) {
        return;
      }

      this.receiptLoading = true;

      try {
        await api.sendReceiptEmail({
          email,
          paymentIntentId: this.paymentIntentId,
        });

        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_SEND_RECEIPT`,
          this.multiplePayEventsInfo,
        );

        this.modalReceipt = true;
        this.modalStatus = false;
        this.modalSorry = false;
      } catch (e) {
        this.warn(e);
      } finally {
        this.receiptLoading = false;
      }
    },
    async sendCheckoutReceipt(email) {
      if (!this.checkoutPaymentId) {
        return;
      }

      this.receiptLoading = true;

      try {
        await api.sendCheckoutReceiptEmail({
          email,
          checkoutPaymentId: this.checkoutPaymentId,
        });

        this.modalReceipt = true;
        this.modalStatus = false;
        this.modalSorry = false;
      } catch (e) {
        this.warn(e);
      } finally {
        this.receiptLoading = false;
      }
    },
    async sendPayPalReceipt(email) {
      if (!this.payPalPaymentId) {
        return;
      }

      this.receiptLoading = true;

      try {
        await api.sendCheckoutReceiptEmail({
          email,
          checkoutPaymentId: this.payPalPaymentId,
        });

        this.modalReceipt = true;
        this.modalStatus = false;
        this.modalSorry = false;
      } catch (e) {
        this.warn(e);
      } finally {
        this.receiptLoading = false;
      }
    },
    async sendPhone({ paymentIntentId } = {}) {
      if (!this.form.phoneNumber) {
        return;
      }

      this.sorryLoading = true;

      try {
        if (this.receipted) {
          await this.sendReceipt(this.form.phoneNumber);
        }

        if (paymentIntentId) {
          await api.sendPhoneV3({
            phoneNumber: this.form.phoneNumber,
            paymentIntentId,
          });
        } else {
          await api.sendPhone({
            phoneNumber: this.form.phoneNumber,
            feedbackId: this.feedbackId,
          });
        }
      } catch (e) {
        this.warn(e);
      } finally {
        this.sorryLoading = false;
      }
    },
    async sendCheckoutPaymentFeedbackInfo({ checkoutPaymentId } = {}) {
      if (!this.form.phoneNumber) {
        return;
      }

      this.sorryLoading = true;

      try {
        let requestData = {};

        if (this.form.phoneNumber.includes('@')) {
          requestData = {
            email: this.form.phoneNumber,
            checkoutPaymentId,
          };
        } else {
          requestData = {
            phoneNumber: this.form.phoneNumber,
            checkoutPaymentId,
          };
        }

        await api.sendCheckoutPaymentFeedbackInfo(requestData);
      } catch (e) {
        this.warn(e);
      } finally {
        this.sorryLoading = false;
      }
    },
    async sendCheckoutFeedbackInfo({ feedbackId } = {}) {
      if (!this.form.phoneNumber) {
        return;
      }

      this.sorryLoading = true;

      try {
        let requestData = {};

        if (this.form.phoneNumber.includes('@')) {
          requestData = {
            email: this.form.phoneNumber,
            feedbackId,
          };
        } else {
          requestData = {
            phoneNumber: this.form.phoneNumber,
            feedbackId,
          };
        }

        await api.sendCheckoutFeedbackInfo(requestData);
      } catch (e) {
        this.warn(e);
      } finally {
        this.sorryLoading = false;
      }
    },
    clickedLink(linkName) {
      this.$amplitude.event(
        `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_${linkName}_OPEN`,
        this.multiplePayEventsInfo,
      );
    },
    transactionHasCollision() {
      const {
        totalAmount,
        payoutId,
        workplacePayoutId,
      } = this.getApiPaymentData();

      return checkTransactionCollision({
        locationPayoutId: workplacePayoutId,
        amount: totalAmount,
        staffId: payoutId,
      });
    },
    onFeeLoadingChanged(value) {
      this.feeLoading = value;
    },
    onTypingChanged(value) {
      this.typing = value;
    },
    handleSubmit(method, skipCollisionCheck = false) {
      if (this.someLoading) {
        return;
      }

      if (!skipCollisionCheck && this.transactionHasCollision() && this.form.amountSum) {
        this.abortedWithSubmitMethod = method;
        this.showSubmitPayModal = true;
        return;
      }

      if (typeof method === 'function') {
        method();

        return;
      }

      if (method === 'submitCheckoutFeedback') {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_FEEDBACK_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitFeedback') {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_FEEDBACK_PRESS`,
          this.amplitudePaymentData,
        );

        if (this.showModalSorry) {
          this.modalSorry = this.sendPhone;
        }
      }

      if (method === 'submitPay') {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitPayByCard') {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitCheckoutPayByCard') {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_CHECKOUT_PAY_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitGiroPay') {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_GIROPAY_PRESS`,
          this.amplitudePaymentData,
        );
      }

      this[method]();
    },
    submitPayInModal() {
      this.showSubmitPayModal = false;
      this.handleSubmit(this.abortedWithSubmitMethod, true);
      this.abortedWithSubmitMethod = null;
    },
    redirectToLocation() {
      this.$router.push({
        name: 'home',
        params: {
          locationPayoutId: this.locationPayoutId,
        },
      });
    },
    submitStatus() {
      if (this.modalStatus) {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_STATUS_OK`,
          this.amplitudePaymentData,
        );
      }

      if (this.modalReceipt) {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_RECEIPT_OK`,
          this.amplitudePaymentData,
        );
      }

      if (!this.hasGroups && !this.hasList) {
        window.location.href = 'https://www.easytip.net';
        return;
      }

      this.redirectToLocation();
      this.reset();
    },
    async submitSorry() {
      this.$amplitude.event(
        `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_SORRY_${this.form.phoneNumber ? 'OK' : 'NOT_NOW'}`,
        this.amplitudePaymentData,
      );

      await this.modalSorry.call(this);

      if (this.modalStatus) {
        return;
      }

      if (this.feedbackSent) {
        this.submitStatus();
      } else {
        this.modalStatus = 'success';
        this.$nextTick(() => {
          this.modalSorry = null;
        });
      }
    },
    async submitFeedback() {
      this.feedbackLoading = true;

      try {
        const { data } = await api.sendFeedback(this.getApiPaymentData(true));

        if (data.id) {
          this.feedbackId = data.id;
        }

        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_FEEDBACK_SUCCESS`,
          this.amplitudePaymentData,
        );

        this.feedbackSent = true;

        if (!this.showModalSorry) {
          this.modalStatus = 'success';
        }

        return;
      } catch (e) {
        this.debug(e);
      } finally {
        this.feedbackLoading = false;
      }

      this.$amplitude.event(
        `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_FEEDBACK_FAILED`,
        this.amplitudePaymentData,
      );

      this.modalStatus = 'error';
    },
    async submitCheckoutFeedback() {
      this.feedbackLoading = true;

      try {
        const {
          groupId = '',
          listFeedback,
          workplacePayoutId,
        } = this.getApiPaymentData(true);

        const { data } = await api.sendCheckoutFeedback({
          groupId,
          listFeedback,
          workplacePayoutId,
        });

        if (data.id) {
          this.feedbackId = data.id;
        }

        // amplitude event

        this.feedbackSent = true;

        if (this.showModalSorry) {
          this.modalSorry = () => {
            return this.sendCheckoutFeedbackInfo({
              feedbackId: this.feedbackId,
            });
          };
        }

        this.modalStatus = 'success';

        return;
      } catch (e) {
        this.debug(e);
      } finally {
        this.feedbackLoading = false;
      }

      // amplitude event

      this.modalStatus = 'error';
    },
    submitPay() {
      this.payLoading = true;

      try {
        // @see {@link https://stripe.com/docs/stripe-js/elements/payment-request-button}
        this.stripePaymentRequest.update({ total: this.stripePaymentData.total });
        this.stripePaymentRequest.show();
        this.stripePaymentRequest.on('paymentmethod', async (ev) => {
          try {
            const {
              data: {
                secret,
                paymentIntentId,
              },
            } = await api.createMobilePayment({
              ...(this.getApiPaymentData()),
              successUrl: this.successUrl,
              errorUrl: this.errorUrl,
            }, this.apiVersion);

            this.$amplitude.event(
              `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_`
              + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_CONFIRM`,
              { secret, paymentIntentId, ...this.amplitudePaymentData },
            );

            const {
              paymentIntent,
              error: confirmError,
            } = await this.stripe.confirmCardPayment(
              secret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false },
            );

            if (confirmError) {
              this.$amplitude.event(
                `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_`
                + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_FAILED`,
                { secret, paymentIntent, ...this.amplitudePaymentData },
              );

              this.debug(confirmError);
              this.payLoading = false;
              await this.$router.push({ name: 'payment-failed' });

              ev.complete('fail');
            } else {
              ev.complete('success');

              if (paymentIntent.status === 'requires_action') {
                const { error } = await this.stripe.confirmCardPayment(secret);
                if (error) {
                  this.$amplitude.event(
                    `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_`
                    + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_FAILED`,
                    { secret, paymentIntent, ...this.amplitudePaymentData },
                  );

                  this.debug(error);
                  this.payLoading = false;
                  await this.$router.push({ name: 'payment-failed' });

                  return;
                }
              }

              this.paymentIntentId = paymentIntentId;

              this.$amplitude.event(
                `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_`
                + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_SUCCESS`,
                { secret, paymentIntent, ...this.amplitudePaymentData },
              );

              this.sendConfirm({ paymentIntentId });

              this.payLoading = false;

              if (this.showModalSorry) {
                this.modalSorry = () => {
                  return this.sendPhone({ paymentIntentId });
                };
              } else {
                this.modalStatus = 'success';
              }
            }
          } catch (e) {
            this.$amplitude.event(
              `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_`
              + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_FAILED`,
              this.amplitudePaymentData,
            );

            this.debug(e);
            this.payLoading = false;
            await this.$router.push({ name: 'payment-failed' });
          }
        });

        this.stripePaymentRequest.on('cancel', () => {
          this.$amplitude.event(
            `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_`
            + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_CANCEL`,
            this.amplitudePaymentData,
          );

          this.payLoading = false;
        });
      } catch (e) {
        this.debug(e);
        this.payLoading = false;
        this.$router.push({ name: 'payment-failed' });
      }
    },
    async submitPayByCard() {
      this.cardLoading = true;

      try {
        const {
          data: {
            sessionId,
          },
        } = await api.createPayment({
          ...(this.getApiPaymentData()),
          successUrl: this.successUrl,
          errorUrl: this.errorUrl,
        }, this.apiVersion);

        if (sessionId) {
          this.$amplitude.event(`${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_REDIRECT`, {
            ...this.amplitudePaymentData,
            sessionId,
          });

          this.stripe.redirectToCheckout({ sessionId });

          return;
        }
      } catch (e) {
        this.debug(e);
      } finally {
        this.cardLoading = false;
      }

      this.$amplitude.event(
        `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_WRONG_SESSION`,
        this.amplitudePaymentData,
      );

      await this.$router.push({ name: 'payment-failed' });
    },
    submitCheckoutPayByCard() {
      const {
        feeAmount,
        kycProcessed,
        phoneNumber,
        totalAmount,
        listFeedback,
        feeChecked,
        ...data
      } = this.getApiPaymentData();

      this.$router.push({
        name: 'checkout',
        query: {
          ...data,
          feeChecked: feeChecked.toString(),
          currencyIso: this.currency.iso,
          currencyLabel: this.currency.label,
          currencyRightPosition: +this.currency.rightPosition,
          listPayment: JSON.stringify(data.listPayment
            ? data.listPayment
            : []),
          cards: JSON.stringify(data.cards
            ? data.cards
            : []),
          backUrl: JSON.stringify({
            rate: this.form.rate,
            locationId: this.$route.params.locationPayoutId,
            query: this.$route.query,
            checkoutPayment: this.isCheckoutPayment,
            amount: totalAmount,
          }),
        },
      });
    },
    async submitGiroPay() {
      this.giroPayLoading = true;

      try {
        const {
          data: {
            secret,
          },
        } = await api.createGiroPayPayment({
          ...(this.getGiroPayPaymentData()),
          successUrl: this.successUrl,
          errorUrl: this.errorUrl,
        });

        if (secret) {
          await this.stripe.confirmGiropayPayment(
            secret,
            {
              payment_method: {
                billing_details: {
                  name: this.staffNames || 'NO NAME',
                },
              },
              return_url: this.giroPayUrl,
            },
          );

          return;
        }
      } catch (e) {
        this.debug(e);
      } finally {
        this.giroPayLoading = false;
      }

      await this.$router.push({ name: 'payment-failed' });
    },
    async handleGiroPayStatus(amplitudePaymentData) {
      try {
        const response = await this.stripe.retrievePaymentIntent(this.paymentIntentClientSecret);

        if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
          if (!this.showModalSorry) {
            this.modalStatus = 'success';
          }

          this.cardPay = true;

          const {
            paymentIntent,
          } = this;

          this.sendGiroPayConfirm({ paymentIntentId: paymentIntent });

          if (this.showModalSorry) {
            this.modalSorry = () => {
              return this.sendPhone({ paymentIntentId: paymentIntent });
            };
          } else {
            this.modalStatus = 'success';

            this.$amplitude.event(
              `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_GIROPAY_SUCCESS`,
              amplitudePaymentData,
            );
          }
        } else {
          this.$amplitude.event(
            `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_GIROPAY_ERROR`,
            amplitudePaymentData,
          );

          await this.$router.push({ name: 'payment-failed' });
        }
      } catch (e) {
        this.debug(e);
      }
    },
    async checkPayPalPayment(data) {
      try {
        await api.checkoutPaymentConfirm(data);
      } catch (e) {
        this.debug(e);
        this.modalStatus = 'error';
      }
    },
    onStaffOpened({ payoutId }) {
      this.$amplitude.event('SALON_STAFF_SELECT', {
        payoutId,
        ...this.multiplePayEventsInfo,
      });
    },
    onGroupOpened({ payoutId }) {
      this.$amplitude.event('SALON_GROUP_SELECT', {
        payoutId,
        ...this.multiplePayEventsInfo,
      });
    },
    onSubLocationOpened({ payoutId }) {
      this.$amplitude.event('SUB_LOCATION_SELECT', { payoutId });
    },
    applePayButtonAmplitudeEvent(show, trueEvent, falsyEvent) {
      if (show) {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_APPLE_PAY_${trueEvent}`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_APPLE_PAY_${falsyEvent}`,
          this.amplitudePaymentData,
        );
      }
    },
    googlePayButtonAmplitudeEvent(show, trueEvent, falsyEvent) {
      if (show) {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_GOOGLE_PAY_${trueEvent}`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_GOOGLE_PAY_${falsyEvent}`,
          this.amplitudePaymentData,
        );
      }
    },
    payByCardButtonAmplitudeEvent(show, trueEvent, falsyEvent) {
      if (show) {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_${trueEvent}`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.isStaff ? 'STAFF' : 'RESTAURANT'}_PAY_BY_CARD_${falsyEvent}`,
          this.amplitudePaymentData,
        );
      }
    },
    isUserSelectedById(id) {
      return this.selectedSpecialists.findIndex((elem) => elem.payoutId === id) !== -1;
    },
    selectSpecialist({ staff }) {
      const status = !this.isUserSelectedById(staff.payoutId);
      if (status) {
        this.selectedSpecialists.push(staff);
      } else {
        this.selectedSpecialists = this.selectedSpecialists.filter((localStaff) => localStaff.payoutId !== staff.payoutId);
      }
    },
  },

  mounted() {
    const unwatchPayButtons = this.$watch(() => this.showSubmit && !this.onlyFeedback && this.canPay, (shown) => {
      if (this.applePay) {
        this.applePayButtonAmplitudeEvent(shown, 'SHOWN', 'HIDDEN');
      }

      if (this.googlePay) {
        this.googlePayButtonAmplitudeEvent(shown, 'SHOWN', 'HIDDEN');
      }

      if (unwatchPayButtons) {
        unwatchPayButtons();
      }
    });

    const unwatchPayByCard = this.$watch(() => this.showSubmit && !this.onlyFeedback, (shown) => {
      this.payByCardButtonAmplitudeEvent(shown, 'SHOWN', 'HIDDEN');

      if (unwatchPayByCard) {
        unwatchPayByCard();
      }
    });

    const unwatchStaffList = this.$watch(() => this.isFullReceiptMode, async () => {
      if (this.isFullReceiptMode) {
        if (!this.location?.staffs?.length) {
          await this.loadLocationStaffs({
            payoutId: this.locationPayoutId,
          });
        }

        const staff = [];
        this.queryStaffList.forEach(({
          id,
          amount,
          service,
        }) => {
          this.location.staffs.forEach((item) => {
            if (item.payoutId === id) {
              staff.push({
                ...item,
                amount,
                service,
                tips: 0,
                selectedTip: {},
              });
            }
          });
        });

        let filteredStaff = {};

        staff.forEach((o) => {
          const key = JSON.stringify(o);
          filteredStaff[key] = (filteredStaff[key] || 0) + 1;
        });

        filteredStaff = Object.entries(filteredStaff).map(([key, val]) => ({
          ...JSON.parse(key),
          duplicate: val,
        }));

        filteredStaff = filteredStaff.map((item) => ({
          ...item,
          amount: item.amount * item.duplicate,
        }));

        this.receiptCommonList = filteredStaff;
        this.receiptCommon.staff = filteredStaff;
        this.receiptSeparate.common = filteredStaff;
        this.receiptCommonTotal = this.receiptCommonSumField('amount');

        const filterByPayoutId = (arr, payoutId) => {
          return arr.filter((item) => payoutId === item.payoutId);
        };

        filteredStaff = filteredStaff.map((item) => {
          return {
            ...item,
            complicated: Array.isArray(item.amount),
            amount: filterByPayoutId(filteredStaff, item.payoutId).length > 1
              ? filterByPayoutId(filteredStaff, item.payoutId).reduce((acc, { amount }) => acc + amount, 0)
              : item.amount,
            service: filterByPayoutId(filteredStaff, item.payoutId).length > 1
              ? filterByPayoutId(filteredStaff, item.payoutId).map(({ service, duplicate }) => `x${duplicate} ${service}`)
              : [item.service],
            duplicate: filterByPayoutId(filteredStaff, item.payoutId).length > 1
              ? filterByPayoutId(filteredStaff, item.payoutId).map(({ duplicate }) => duplicate)
              : item.duplicate,
          };
        });

        let filteredSeparate = [];
        filteredStaff.forEach((o) => {
          const key = JSON.stringify(o);
          filteredSeparate[key] = (filteredSeparate[key] || 0) + 1;
        });

        filteredSeparate = Object.entries(filteredSeparate).map(([key]) => ({
          ...JSON.parse(key),
        }));

        this.receiptSeparate.staff = filteredSeparate;
        this.receiptSeparateTotal = this.receiptSeparateSumField('amount');
      }

      if (unwatchStaffList) {
        unwatchStaffList();
      }
    });
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

@function home-gradient($offset) {
  @return linear-gradient(#fff $offset, #fcb77b $offset, #a01212 100%);
}

.home {
  min-height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  background-image: home-gradient($header-default-top);

  &_multipay {
    .home {
      &__user-name {
        font-size: 20px;
        line-height: 26px;
      }
      &__user-amount {
        .user-amount__input {
          margin-bottom: 0;
        }
        input {
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;

          &::placeholder {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  &_frp-mode {
    .home {
      &__user-location {
        font-size: 24px;
        line-height: 28px;
        margin-top: 12px;
      }

      &__staff-list {
        margin-top: 30px;
      }

      &__ui-tips {
        margin-bottom: 12px;
      }

      &__total-price {
        margin: 16px 0 11px;
      }

      &__user-amount {
        .ui-input__control {
          height: 40px;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
        }

        .ui-input__clear {
          top: 8px;
          width: 20px;
          height: 20px;
          font-size: 22px;
        }

        .ui-input__control::-moz-placeholder {
          font-weight: 400;
        }

        .ui-input__control::-webkit-input-placeholder {
          font-weight: 400;
        }
      }
    }
  }

  &__header {
    height: $header-default-top;
    max-width: $max-width;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;
  }

  &__logo {
    height: 60px;
    margin: 20px;
  }

  &__locale {
    position: absolute;
    top: 20px;
    right: 0;
  }

  &_prestep {
    background-image: home-gradient($header-prestep-top);
  }
  &_prestep &__header {
    height: $header-prestep-top;
  }
  &_prestep &__prestep-avatar {
    margin: 20px auto;
  }

  &__body {
    padding: 20px;
    max-width: $max-width + 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
  }

  &__search-title {
    margin: 2px auto 22px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }
  &__multiple-switcher {
    margin: 10px 0 32px;
    display: flex;
    align-self: flex-start;
  }
  &__search-filter {
    margin-bottom: 16px;
    width: 100%;
  }
  &__search-staff,
  &__search-groups,
  &__search-sub-locations {
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;

    & + & {
      margin-top: 12px;
    }
  }

  &__user-avatar {
    margin-top: -74px;
  }

  &__user-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    padding: 0 32px;
    margin: initial;
    &-row {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
      margin-top: 12px;
      min-height: 32px;
    }
  }

  &__back-btn {
    position: absolute;
    left: 0;
  }

  &__user-location {
    font-size: 14px;
    margin: 0 0 10px 0;
  }

  &__tip-selector {
    margin-top: 22px;
  }

  &__user-wish {
    margin-bottom: 24px;
  }

  &__user-bill {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  &__tips {
    margin-top: 18px;
    margin-bottom: 40px;
  }

  &__user-reviews {
    margin-top: 30px;
  }

  &__user-comment {
    margin-top: 30px;
    margin-bottom: 12px;
  }

  &__user-rates + &__user-amount {
    margin-top: 32px;
  }

  &__user-buttons {
    margin-top: 40px;

    .ui-button + .ui-button {
      margin-top: 12px;
    }
  }

  &__user-staff_no-padding {
    padding: 0;
  }

  .ui-card + .ui-card {
    margin-top: 17px;
  }

  &__total-price {
    margin: 30px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }
}
</style>
