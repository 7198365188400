<template>
  <div
    class="ui-input"
    :class="{
      'ui-input_small': small,
      'ui-input_clearable': showClear,
      'ui-input_danger': error,
      'ui-input_disabled': disabled,
      'ui-input_focused': focused,
    }"
  >
    <ui-input-money
      v-if="tag === 'money'"
      v-bind="$attrs"
      class="ui-input__control"
      input-class="ui-input__input"
      :value="inputValue"
      :disabled="disabled"
      @input="handleInput($event)"
      @focus="focused = true"
      @blur="focused = false"
    ></ui-input-money>
    <input
      v-else-if="tag === 'input'"
      v-bind="$attrs"
      ref="input"
      class="ui-input__control ui-input__input"
      :value.prop="inputValue"
      :disabled="disabled"
      @input="handleInput($event.target.value)"
    />
    <component
      v-else
      :is="tag"
      v-bind="$attrs"
      ref="input"
      class="ui-input__control ui-input__input"
      :value="inputValue"
      :disabled="disabled"
      @input="handleInput($event.target.value)"
    ></component>
    <div
      v-if="showClear"
      class="ui-input__clear"
      @click="clear"
    >
      &times;
    </div>
    <p class="ui-input__error-text" v-if="error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import UiInputMoney from './UiInputMoney.vue';

export default {

  inheritAttrs: false,

  components: {
    UiInputMoney,
  },

  props: {
    tag: {
      type: String,
      default: 'input',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: String,
    value: null,
    small: Boolean,
    clearable: Boolean,
  },

  data() {
    return {
      inputValue: this.value,
      focused: false,
      touched: false,
      updateValue: debounce((value) => {
        this.touched = false;
        this.$emit('input', value);
        this.$emit('update:typing', false);
      }, 400),
    };
  },

  computed: {
    isEmpty() {
      return !this.inputValue || `${this.inputValue}`.length === 0;
    },
    showClear() {
      return !this.isEmpty && this.clearable;
    },
  },

  watch: {
    value(value) {
      if (value !== this.inputValue && !this.touched) {
        this.inputValue = value;
      }
    },
  },

  mounted() {
    if (this.tag === 'textarea') {
      this.$nextTick(() => {
        this.autoHeight();
      });
    }
  },

  methods: {
    handleInput(value) {
      this.touched = true;
      this.$emit('update:typing', true);
      this.updateValue(value);

      if (this.tag === 'textarea') {
        this.autoHeight();
      }
    },
    autoHeight() {
      if (this.$refs?.input) {
        const el = this.$refs.input;

        el.style.height = 'auto';
        el.style.height = `${el.scrollHeight}px`;
      }
    },
    clear() {
      if (this.disabled) {
        return;
      }

      this.inputValue = '';
      this.handleInput('');
    },
  },
};
</script>

<style lang="scss">
.ui-input {
  position: relative;

  textarea {
    resize: none;
  }

  &__input {
    background: transparent;
    border: 0;
    font-size: 36px;
    color: #000;
    height: 50px;
    line-height: 48px;
    box-sizing: border-box;
    padding: 0;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      font-size: 20px;
      color: rgba(0,0,0,0.5);
      vertical-align: middle;
      opacity: 1;
    }
  }

  &__control:focus,
  &_focused &__control {
    border-bottom-color: #b5272f;
  }

  &__control {
    font-size: 36px;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    padding: 0;
    width: 100%;
    overflow-y: hidden;
  }

  &__clear {
    position: absolute;
    top: 14px;
    right: 0;
    width: 24px;
    height: 24px;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 100%;
    font-size: 26px;
    color: #A01212;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &_small &__control {
    font-size: 14px;
    line-height: 14px;
    height: auto;
    padding-bottom: 10px;
    border-bottom-width: 1px;

    &::placeholder {
      font-size: 14px;
    }
  }

  &_clearable &__control {
    padding-right: 30px;
  }

  &__error-text {
    margin-top: 6px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  &_danger &__control {
    color: #FF0000;
    border: 0;
    border-color: #FF0000;
    border-bottom: 2px solid #FF0000;
  }

  &_danger &__error-text {
    color: #FF0000;
  }

  &_disabled &__clear {
    cursor: default;
  }
}

.it .ui-input__control {
  &::placeholder {
    font-size: 16px;
  }
}
</style>
