<template>
  <div class="base-layout" :class="{ 'base-layout_vertical-centering': verticalCentering }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseLayout',
  props: {
    verticalCentering: Boolean,
  },
};
</script>

<style lang="scss">
.base-layout {
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  background: linear-gradient(180deg, #FCB77B 0%, #A01212 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  &_vertical-centering {
    justify-content: center;
  }
}
</style>
