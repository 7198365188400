<template>
  <ui-modal
    :title="$t('modal.fee.title')"
    class="modal-fee"
    :disabled="disabled"
    v-on="$listeners"
  >
    <template #activator="{ open }">
      <ui-icon-button
        name="question"
        size="8px"
        class="modal-fee__button"
        @click="open"
        :disabled="disabled"
      />
    </template>

    <template #default="{ close }">
      <div class="modal-fee__text">
        <p>{{ $t('modal.fee.text[0]') }}</p>
        <p>{{ $t('modal.fee.text[1]') }}</p>
        <p>{{ $t('modal.fee.text[2]') }}</p>
      </div>
      <ui-button @click="close">
        {{ $t('modal.fee.button') }}
      </ui-button>
    </template>
  </ui-modal>
</template>

<script>
import UiIconButton from './UiIconButton.vue';
import UiButton from './UiButton.vue';
import UiModal from './UiModal.vue';

export default {

  inheritAttrs: false,

  components: {
    UiIconButton,
    UiButton,
    UiModal,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.modal-fee {
  &__button {
    width: 14px;
    height: 14px;
    background: #c4c4c4;
    border-radius: 50%;
    color: #fff;
    margin-left: 4px;
    opacity: 0.8;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 35px;
  }
}
</style>
