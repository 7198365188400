import api from '@/api';
import i18n from '@/plugins/i18n';
import { API_SWITCH_ADMIN } from '@/config/api';

import factory from '../factory';

const isDateExpired = (date) => {
  return (Date.now() - date) >= process.env.VUE_APP_STAFF_TYPES_CACHE_TIME;
};

const getServer = (country) => {
  return API_SWITCH_ADMIN.find((it) => it.country.includes(country))?.name || 'eu';
};

export default factory({

  namespaced: true,

  state: () => ({
    server: '',
    version: null,
    date: null,
    types: null,
    isLoading: false,
  }),

  getters: {
    staffTypes(state) {
      if (!state.types) {
        return [];
      }

      const MASK = {
        en: 'UK',
        de: 'DE',
      };
      const LANG = MASK[i18n.locale] || 'UK';

      return state.types
        .map((data) => ({
          ...data,
          name: data.name[LANG],
        }));
    },
    staffTypeName(state, getters) {
      return (staffType) => getters.staffTypes
        .find(({ value }) => value === staffType)
        ?.name || 'UNKNOWN';
    },
  },

  actions: {
    async loadVersion({ commit }) {
      const {
        data: {
          versionList,
        },
      } = await api.getStaffTypesVersion();

      const server = getServer(i18n.locale);
      const version = versionList.find(({ name }) => name === 'STAFF_TYPE')?.version;
      const date = Date.now();

      commit('set', {
        server,
        version,
        date,
      });
    },
    async loadStaffTypes({ commit }) {
      const { data } = await api.getStaffTypes();

      const filteredStaffTypes = data
        .filter(({ status }) => status === 'ACTIVE')
        .map((type) => ({
          id: type.id,
          name: type.localeValueMap,
          value: type.name,
          types: type.availableWorkplaceTypes,
          countries: type.countryISOCodeList,
        }));

      commit('set', {
        types: filteredStaffTypes,
      });
    },
    async getStaffTypes({ state, commit, dispatch }) {
      if (state.isLoading) {
        return;
      }

      commit('set', {
        isLoading: true,
      });

      try {
        if (!state.version || !state.types || state.server !== getServer(i18n.locale)) {
          await dispatch('loadVersion');
          await dispatch('loadStaffTypes');
        } else if (isDateExpired(state.date)) {
          const { version } = state;

          await dispatch('loadVersion');

          if (version !== state.version) {
            await dispatch('loadStaffTypes');
          }
        }
      } finally {
        commit('set', {
          isLoading: false,
        });
      }
    },
  },
});
