<template>
  <div class="absolute-footer">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AbsoluteFooter',
};
</script>

<style lang="scss">
.absolute-footer {
  position: absolute;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.16);
  width: 100vw;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
