<template>
  <ui-card>
    <div class="ui-staff-list">
      <ul class="ui-staff-list__items">
        <li
          v-for="item in staff"
          :key="item.id"
          class="ui-staff-list__item"
        >
          <user-avatar
            :payout-id="item.payoutId"
            class="ui-staff-list__avatar"
            size="micro"
          />
          <div class="ui-staff-list__data">
            <div class="ui-staff-list__info">
              <span class="ui-staff-list__service">
                {{ item.service }}
              </span>
              <span class="ui-staff-list__fio">
                {{ item.firstName }}
              </span>
            </div>
            <div class="ui-staff-list__value">
              <span class="ui-staff-list__amount">
                <span
                  v-if="item.duplicate > 1"
                  class="ui-staff-list__dpl-label"
                >
                  {{ item.duplicate }}
                </span>
                {{ duplicateMoney(item) }}
              </span>
              <span
                v-if="item.tips"
                class="ui-staff-list__tips"
              >
                +{{ tipsMoney(item) }} tips
              </span>
            </div>
          </div>
        </li>
      </ul>
      <div class="ui-staff-list__common">
        <span
          v-if="vat"
          class="ui-staff-list__vat"
        >
          VAT Included {{ vatMoney }}
        </span>
        <span class="ui-staff-list__total-amount">
          Bill amount: {{ totalMoney }}
        </span>
      </div>
      <slot />
      <ui-switcher
        @input="$emit('input', !value)"
        :value="value"
        labelColor="#000000"
        sliderColor="#E5E5E5"
        label="Leave tips manually"
        class="ui-staff-list__manualy-switcher"
      />
    </div>
  </ui-card>
</template>

<script>
import Money from '@/class/Money';
import UiCard from './UiCard.vue';
import UserAvatar from './UserAvatar.vue';
import UiSwitcher from './UiSwitcher.vue';

export default {
  name: 'UiStaffList',

  components: {
    UiCard,
    UserAvatar,
    UiSwitcher,
  },

  props: {
    staff: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    vat: Number,
    currency: Object,
  },

  data() {
    return {
      tipsManualy: false,
    };
  },

  computed: {
    totalAmount() {
      return this.staff.reduce((a, s) => a + Number(s.amount), 0);
    },
    totalMoney() {
      return new Money(this.totalAmount, this.currency);
    },
    vatMoney() {
      return new Money(this.vat, this.currency);
    },
  },

  methods: {
    duplicateMoney({ amount, duplicate }) {
      return new Money(amount / duplicate, this.currency);
    },
    tipsMoney({ tips }) {
      return new Money(tips, this.currency);
    },
  },
};
</script>

<style lang="scss">
  .ui-staff-list {
    &__items {
      margin-block-start: 0;
      padding-inline-start: 0;
      border-bottom: 2px solid #CCCCCC;
      padding-bottom: 20px;
    }

    &__item {
      max-width: 100%;
      display: flex;
      list-style: none;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    &__avatar {
      margin-right: 17px;
    }

    &__data {
      width: 83%;
      display: flex;
      align-items: center;
    }

    &__info,
    &__value {
      display: flex;
      flex-direction: column;
    }

    &__info {
      flex: 1;
      min-width: 0;
    }

    &__value {
      align-items: flex-end;
    }

    &__service {
      flex: 1;
      min-width: 0;
      align-items: flex-start;
      margin-right: 15px;
      margin-bottom: 8px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__amount {
      display: flex;
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__dpl-label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      font-size: 8px;
      font-weight: 900;
      margin-right: 10px;
      background: #F0F0F0;
    }

    &__fio,
    &__tips {
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: #818181;
    }

    &__common {
      display: flex;
      flex-direction: column;
      margin-bottom: 46px;
    }

    &__vat {
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    &__total-amount {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__manualy-switcher {
      margin: 10px 0;

      .ui-switch__label {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
</style>
