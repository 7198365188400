<template>
  <div class="user-wish">
    {{ value }}
  </div>
</template>

<script>
export default {

  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.user-wish {
  text-align: center;
  background-color: #311e10;
  font-size: 14px;
  line-height: 1.29;
  color: #fff;
  padding: 8px 14px;
  border-radius: 18px;
  border-top-left-radius: 0;
  position: relative;
  min-height: 18px;

  &:after {
    content: '';
    display: block;
    background-color: #311e10;
    clip-path: polygon(0 6px, -4px 0, 19px 130%);
    width: 16px;
    height: 5px;
    position: absolute;
    left: 0;
    top: -5px;
  }
}
</style>
