<template>
  <div class="location-info">
    <div class="location-info__avatar">
      <user-avatar :payout-id="payoutId" />
    </div>
    <div class="location-info__data">
      <div v-if="locationName" class="location-info__name">
        {{ locationName }}
      </div>
      <div v-if="contactInfo.address" class="location-info__address">
        {{ contactInfo.address }}
      </div>
      <div class="location-info__socials">
        <a
          v-if="contactInfo.instagramUrl"
          :href="contactInfo.instagramUrl"
          target="_blank"
        >
          <ui-icon-button name="instagram" size="28px" />
        </a>
        <a
          v-if="contactInfo.facebookUrl"
          :href="contactInfo.facebookUrl"
          target="_blank"
        >
          <ui-icon-button name="facebook" size="28px" />
        </a>
        <a
          v-if="contactInfo.phoneNumber"
          :href="`tel:${contactInfo.phoneNumber}`"
          class="location-info__phone-link"
        >
          <ui-icon-button
            name="phone"
            size="16px"
          />
          <div class="location-info__phone">
            {{ contactInfo.phoneNumber }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import UiIconButton from '@/components/UiIconButton.vue';
import UserAvatar from '@/components/UserAvatar.vue';

export default {
  name: 'LocationInfo',

  components: {
    UserAvatar,
    UiIconButton,
  },

  props: {
    payoutId: {
      type: String,
      required: true,
    },
    locationName: {
      type: String,
      default: '',
    },
    contactInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>
