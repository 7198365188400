<template>
  <div
    class="user-avatar"
    :class="{ [`user-avatar_${size}`]: size }"
  >
    <img :src="image" />
  </div>
</template>

<script>
import api from '@/api';

const memoryCache = {};
const noimage = require('@/assets/noimage.png');

export default {

  props: {
    payoutId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    image: noimage,
  }),

  async mounted() {
    if (this.payoutId in memoryCache) {
      this.image = memoryCache[this.payoutId];

      return;
    }

    try {
      const { data } = await api.getAvatar(this.payoutId);

      this.image = URL.createObjectURL(data);
    } catch (e) {
      // noop
    }

    memoryCache[this.payoutId] = this.image;
  },
};
</script>

<style lang="scss">
.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #fff;

  &_small {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }

  &_tiny {
    width: 76px;
    height: 76px;
    border: none;
  }

  &_micro {
    width: 40px;
    height: 40px;
    border: none;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
