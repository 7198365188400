<template>
  <ui-modal
    v-model="show"
    v-bind="$attrs"
    class="modal-receipt"
    v-on="$listeners"
    :closable="false"
  >
    <template #header>
      <img
        src="@/assets/ok.svg"
        class="modal-receipt__image"
      />
      <div>
        {{ $t('modal.success.title') }}
      </div>
    </template>

    <template #default>
      <div class="modal-receipt__text">
        {{ $t('modal.success.subTitle') }}
      </div>
      <ui-button
        @click="$emit('closed', $event)"
        class="modal-status__button"
        small
      >
        Ok
      </ui-button>
      <ui-socials @clicked-link="clickedLink" />
    </template>
  </ui-modal>
</template>

<script>
import UiModal from './UiModal.vue';
import UiButton from './UiButton.vue';
import UiSocials from './UiSocials.vue';

export default {

  components: {
    UiModal,
    UiButton,
    UiSocials,
  },

  data: () => ({
    show: true,
  }),

  methods: {
    clickedLink(linkName) {
      this.$emit('clicked-link', linkName);
    },
  },
};
</script>

<style lang="scss">
  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .modal-receipt {

    text-align: center;

    &__image {
      margin-bottom: 17px;
    }

    &__button {
      display: inline-block;
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 35px;
      font-weight: normal;
    }
  }

  .modal-receipt .ui-modal-wrapper.opened {
    background-image: linear-gradient(#fcb77b 120px, #a01212 100%);
  }
</style>
