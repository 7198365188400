<template>
  <base-layout class="location__layout">
    <div class="location__wrapper">
      <location-background
        v-if="backgroundInfo"
        :background-link="backgroundInfo.link"
      />
      <div class="location">
        <location-info
          :payoutId="payoutId"
          :location-name="location.name"
          :contact-info="contactInfo"
        />
        <location-card
          :preview-menu-link="previewMenuLink"
          class="location__card"
        />
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import LocationInfo from '@/components/location/LocationInfo.vue';
import LocationCard from '@/components/location/LocationCard.vue';
import LocationBackground from '@/components/location/LocationBackground.vue';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'Location',

  props: {
    payoutId: {
      type: String,
      required: true,
    },
  },

  components: {
    LocationBackground,
    LocationCard,
    LocationInfo,
    BaseLayout,
  },

  computed: {
    ...mapState({
      backgroundInfo: (state) => state.menu.backgroundInfo,
      contactInfo: (state) => state.menu.contactInfo,
      location: (state) => state.user.location,
    }),
    ...mapGetters({
      previewMenuLink: 'menu/previewMenuLink',
    }),
  },

  methods: {
    ...mapActions({
      loadLocation: 'user/loadLocation',
      loadMenuInfo: 'menu/loadMenuInfo',
    }),
  },

  created() {
    this.loadLocation({ payoutId: this.payoutId });
    this.loadMenuInfo({ payoutId: this.payoutId });
  },
};
</script>
