import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_PUBLIC_PATH,
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  return next();
});

router.afterEach(async () => {
  await router.app.$nextTick();

  NProgress.done();
});

export default router;
