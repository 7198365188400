/* eslint-disable quote-props */

export default {
  spa: {
    'Attendant': 'spa-attendant.svg',
    'Bar': 'spa-bar.svg',
    'Beautician': 'spa-beautician.svg',
    'Cafe': 'spa-cafe.svg',
    'Fitness': 'spa-fitness.svg',
    'Nails': 'spa-nails.svg',
    'Reception': 'spa-reception.svg',
    'Salon': 'spa-salon.svg',
    'Sauna': 'spa-sauna.svg',
    'Yoga': 'spa-yoga.svg',
    'Wellness': 'spa-wellness.svg',
  },
  beauty_salon: {
    'Attendant': 'spa-attendant.svg',
    'Bar': 'spa-bar.svg',
    'Beautician': 'spa-beautician.svg',
    'Cafe': 'spa-cafe.svg',
    'Fitness': 'spa-fitness.svg',
    'Nails': 'spa-nails.svg',
    'Reception': 'spa-reception.svg',
    'Salon': 'spa-salon.svg',
    'Sauna': 'spa-sauna.svg',
    'Yoga': 'spa-yoga.svg',
    'Wellness': 'spa-wellness.svg',
  },
};
