<template>
  <button
    v-bind="$attrs"
    class="ui-tip"
    :class="{
      'ui-tip_active': active,
      'ui-tip_disabled': disabled,
    }"
    v-on="$listeners"
    :disabled="disabled"
  >
    <div class="ui-tip__title">
      <span
        v-if="caption"
        class="ui-tip__caption"
      >
        {{ caption }}
      </span>
      <template v-if="percentage">
        {{ value }}
      </template>
      <ui-money
        v-else
        :amount="value"
        :currency="currency"
      />
    </div>
  </button>
</template>

<script>
import UiMoney from './UiMoney.vue';

export default {

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: Object,
      required: true,
    },
    caption: String,
    disabled: Boolean,
    active: Boolean,
    percentage: Boolean,
  },

  components: {
    UiMoney,
  },
};
</script>

<style lang="scss">
.ui-tip {
  background: none;
  border: 0;
  padding: 5px 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &_active {
    padding: 2px;
    border: 2px solid #b65c1d;
    border-radius: 12px;
  }

  &_disabled {
    cursor: default;
  }

  &__title {
    background: linear-gradient(180deg, #FFD700 0%, #F33229 100%);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    opacity: 0.8;
    border-radius: 8px;
    font-weight: 500;
    font-size: 17px;
    height: 40px;
    line-height: 40px;
    color: #fff;
  }

  &__caption {
    display: none;
    margin: 0;
    padding: 0;
    font-size: 11px;
    line-height: 16px;
  }

  &_active > &__title {
    opacity: 1;
  }
}
</style>
