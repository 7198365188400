<template>
  <div class="ui-feedback-links">
    <ui-button
      v-if="feedbackLinks.googleMapsLink"
      class="ui-feedback-links__google-maps-btn"
      @click="goGoogleMapsLink(feedbackLinks.googleMapsLink)"
      href="javascript:void(0);"
      target="_blank"
      tag="a"
    >
      <ui-icon name="google-maps" />
    </ui-button>

    <ui-button
      v-if="feedbackLinks.tripAdvisorLink"
      class="ui-feedback-links__tripadvisor-btn"
      @click="goTripAdvisorLink(feedbackLinks.tripAdvisorLink)"
      href="javascript:void(0);"
      target="_blank"
      tag="a"
    >
      <ui-icon name="tripadvisor" />
    </ui-button>

    <ui-button
      v-if="feedbackLinks.trustPilotLink"
      class="ui-feedback-links__trustpilot-btn"
      @click=goTrustPilotLink(feedbackLinks.trustPilotLink)
      href="javascript:void(0);"
      target="_blank"
      tag="a"
    >
      <ui-icon name="trustpilot" />
    </ui-button>
  </div>
</template>

<script>
import UiButton from './UiButton.vue';
import UiIcon from './UiIcon.vue';

export default {
  components: {
    UiButton,
    UiIcon,
  },

  props: {
    feedbackLinks: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.$amplitude.event('FEEDBACK_LINKS_SHOWN');
  },

  methods: {
    goGoogleMapsLink(link) {
      this.$amplitude.event('GOOGLE_MAPS_LINK_PRESS');
      window.open(link, '_blank');
    },
    goTripAdvisorLink(link) {
      this.$amplitude.event('TRIP_ADVISOR_LINK_PRESS');
      window.open(link, '_blank');
    },
    goTrustPilotLink(link) {
      this.$amplitude.event('TRUST_PILOT_LINK_PRESS');
      window.open(link, '_blank');
    },
  },
};
</script>

<style lang="scss">
.ui-feedback-links {
  margin-bottom: 36px;

  &__google-maps-btn,
  &__tripadvisor-btn,
  &__trustpilot-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 56px;
    margin-top: 12px;
    border-radius: 10px;
    box-sizing: border-box;

    z-index: 9999 !important;
  }

  &__google-maps-btn {
    border: 1px solid #E5E5E5;
    background: #FFFFFF;

    > svg {
      width: 129px;
      height: 26px;
    }
  }

  &__tripadvisor-btn {
    background: #34E0A1;

    > svg {
      width: 117px;
      height: 26px;
    }
  }

  &__trustpilot-btn {
    background: #000032;

    > svg {
      width: 131px;
      height: 28px;
    }
  }
}
</style>
