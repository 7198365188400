import { render, staticRenderFns } from "./UiSocials.vue?vue&type=template&id=063366dc&"
import script from "./UiSocials.vue?vue&type=script&lang=js&"
export * from "./UiSocials.vue?vue&type=script&lang=js&"
import style0 from "./UiSocials.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports