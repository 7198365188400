<template>
  <div class="fee">
    <ui-checkbox
      :value="feeChecked"
      class="fee__checkbox"
      :class="{
        'fee__checkbox_checked': feeChecked,
      }"
      :disabled="disabled"
      small
      black
      @input="$emit('input', $event)"
    />
    <div class="fee__help">
      <span class="fee__label">
        {{ $t('form.fee', { money }) }}
      </span>

      <modal-fee
        class="fee__info"
        :disabled="disabled"
        @opened="$emit('fee-opened')"
      />
    </div>
  </div>
</template>

<script>
import Money from '@/class/Money';
import UiCheckbox from './UiCheckbox.vue';
import ModalFee from './ModalFee.vue';

export default {
  name: 'AmountFee',

  components: {
    UiCheckbox,
    ModalFee,
  },

  model: {
    prop: 'feeChecked',
    event: 'input',
  },

  props: {
    currency: {
      type: Object,
      required: true,
    },
    feeChecked: {
      type: Boolean,
      required: true,
    },
    feeAmount: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    money() {
      return new Money(this.feeAmount, this.currency);
    },
  },
};
</script>

<style lang="scss">
.fee {
  display: flex;
  align-items: center;
  position: relative;

  &:not(#{&}__checkbox_checked) &__help {
    color: rgba(0,0,0,.5);
  }

  &__checkbox {
    opacity: 0.55;
  }

  &__label {
    font-weight: 200;
    font-size: 11px;
    line-height: 14px;
    color: #b3b3b3;
  }

  &__help {
    display: flex;
    align-items: center;
  }
}
</style>
