import { render, staticRenderFns } from "./LocationBackground.vue?vue&type=template&id=60821cae&"
import script from "./LocationBackground.vue?vue&type=script&lang=js&"
export * from "./LocationBackground.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports