<template>
  <base-layout vertical-centering>
    <dialogue-card
      :title="$t('dialogue.paymentFailed.title')"
      :description="$t('dialogue.paymentFailed.description')"
      :action-text="$t('dialogue.paymentFailed.action')"
      class="payment-failed-dialogue"
      @action="tryAgainHandler"
    >
      <template #image>
        <img
          class="payment-failed-dialogue__image"
          src="@/assets/sorry-big.png"
        />
      </template>
    </dialogue-card>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import DialogueCard from '@/components/DialogueCard.vue';

export default {
  name: 'PaymentFailed',
  components: { DialogueCard, BaseLayout },
  data() {
    return {
      backUrl: null,
    };
  },
  methods: {
    tryAgainHandler() {
      if (this.backUrl) {
        window.location.href = this.backUrl;
      } else {
        this.$router.back();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backUrl = from.query?.backUrl || null;
    });
  },
};
</script>

<style lang="scss">
  .payment-failed-dialogue {
    &__image {
      height: 64px;
      width: 64px;
    }
  }
</style>
