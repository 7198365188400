import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './plugins/i18n';
import amplitude from './plugins/amplitude';
import ApiSwitchPlugin from './plugins/api-switch';
import 'normalize.css';
import './validation';
import './assets/sass/index.scss';
import './plugins/sentry';

Vue.config.productionTip = process.env.NODE_ENV !== 'production';

Vue.use(VueMeta);
Vue.use(amplitude);
Vue.use(ApiSwitchPlugin);

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
