import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import i18n from './modules/i18n';
import user from './modules/user';
import cookiePolicy from './modules/cookie-policy';
import staffTypes from './modules/staffTypes';
import menu from './modules/menu';
import fee from './modules/fee';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    i18n,
    user,
    cookiePolicy,
    staffTypes,
    menu,
    fee,
  },

  plugins: [
    createPersistedState({
      paths: [
        'staffTypes.server',
        'staffTypes.version',
        'staffTypes.date',
        'staffTypes.types',
      ],
    }),
  ],
});
