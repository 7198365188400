import Home from '@/views/Home.vue';
import NotActive from '@/views/NotActive.vue';
import PaymentFailed from '@/views/PaymentFailed.vue';
import Checkout from '@/views/Checkout.vue';
import Menu from '@/views/Menu.vue';
import Location from '@/views/Location.vue';

export default [
  {
    path: '/not-active',
    name: 'not-active',
    component: NotActive,
  },
  {
    path: '/payment-failed',
    name: 'payment-failed',
    component: PaymentFailed,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    props: ({ params, query }) => {
      const {
        currency = '',
        feeChecked,
        payoutId = '',
        comment = '',
        stars = 0,
        amount = 0,
        workplacePayoutId = '',
        groupId = '',
        currencyLabel = '',
        currencyIso = '',
        currencyRightPosition = '',
        backUrl,
        paymentStatus = '',
        paymentId = '',
      } = query;

      const isFeeChecked = feeChecked === 'true';
      const totalAmount = parseFloat(amount, 10);
      const listPayment = query.listPayment
        ? JSON.parse(query.listPayment)
        : [];
      const cards = query.cards
        ? JSON.parse(query.cards)
        : '';
      const ckoSessionId = query['cko-session-id'] || '';

      if (listPayment.length === 0) {
        listPayment.push({
          payoutId,
          comment,
          stars: parseInt(stars, 10),
          cards,
          amount: totalAmount,
        });
      }

      const currencyInfo = {
        iso: currencyIso,
        label: currencyLabel,
        rightPosition: Boolean(+currencyRightPosition),
      };

      return {
        feeChecked: isFeeChecked,
        currency,
        amount: totalAmount,
        groupId: groupId || '',
        workplacePayoutId,
        listPayment,
        currencyInfo,
        paymentStatus,
        ckoSessionId,
        paymentId,
        backUrl: backUrl ? JSON.parse(backUrl) : {},
      };
    },
  },
  {
    path: '/:locationPayoutId/menu',
    name: 'menu',
    component: Menu,
    props: ({ params }) => {
      return {
        payoutId: params.locationPayoutId,
      };
    },
  },
  {
    path: '/:locationPayoutId/location',
    name: 'location',
    component: Location,
    props: ({ params }) => {
      return {
        payoutId: params.locationPayoutId,
      };
    },
  },
  {
    path: '/:locationPayoutId/:status?',
    name: 'home',
    component: Home,
    props: ({ params, query }) => {
      const {
        // eslint-disable-next-line camelcase
        session_id,
        // eslint-disable-next-line camelcase
        payment_intent,
        // eslint-disable-next-line camelcase
        payment_intent_client_secret,
        preview = 0,
        bill = 0,
        amount = 0,
        rate = 0,
        mode = '',
        checkoutPaymentId,
        checkoutPayment,
        isApplyCheckoutPayment,
        isGoogleCheckoutPayment,
        isCardCheckoutPayment,
        payPalPayment,
      } = query;

      const payPalCkoSessionId = query?.['cko-session-id'] || '';
      const payPalPaymentId = query?.paymentId || '';

      let isFrpMode = false;
      let queryStaffList = [];
      let vat = 0;
      let staff = '';
      let amt = 0;
      let svc = '';

      staff = !Array.isArray(query.staff)
        ? [query.staff || undefined]
        : query.staff.filter((i) => i);
      amt = !Array.isArray(query.amt)
        ? [query.amt || undefined]
        : query.amt.filter((i) => i);
      svc = !Array.isArray(query.svc)
        ? [query.svc || undefined]
        : query.svc.filter((i) => i);

      const isFrpValid = staff.length === amt.length
        && staff.length === svc.length
        && amt.length === svc.length;

      const isFrpMultiple = isFrpValid
        && staff.length > 1 && amt.length > 1 && svc.length > 1;

      const isFrpSingle = isFrpValid
        && staff[0] && amt[0] && svc[0]
        && staff.length === 1 && amt.length === 1 && svc.length === 1;

      if (isFrpMultiple && mode === 'frp') {
        queryStaffList = staff.map((s, i) => ({
          id: s,
          amount: parseFloat(amt[i].replace(',', '.')).toFixed(2),
          service: svc[i],
        }));
      } else if (isFrpSingle && mode === 'frp') {
        queryStaffList = [{
          id: staff[0],
          amount: parseFloat(amt[0].replace(',', '.')).toFixed(2),
          service: svc[0],
        }];
      }

      if ((isFrpMultiple || isFrpSingle) && mode === 'frp') {
        vat = query?.vat?.includes(',') || query?.vat?.includes('.')
          ? parseFloat(query?.vat.replace(',', '.'))
          : parseFloat(query?.vat);

        isFrpMode = true;
      }

      if (!isFrpMultiple && !isFrpSingle && mode === 'frp') {
        window.location.href = 'https://www.easytip.net';
      }

      let staffId = query.staffid || query.staffId;
      if (!Array.isArray(staffId) && staffId !== undefined) {
        staffId = [staffId];
      }
      const groupId = query.groupid || query.groupId;
      const feeAmount = (query.feeamount || query.feeAmount) || 0;
      const feeChecked = (query.feechecked || query.feeChecked) || true;

      return {
        ...params,
        vat,
        preview: Boolean(preview),
        isFrpMode,
        queryStaffList,
        staffPayoutId: staffId || [],
        groupPayoutId: groupId,
        bill: parseFloat(bill),
        amount: parseFloat(amount),
        feeAmount: parseFloat(feeAmount),
        feeChecked: Boolean(+feeChecked || feeChecked === 'true'),
        sessionId: session_id,
        rate: parseInt(rate, 10),
        paymentIntent: payment_intent,
        paymentIntentClientSecret: payment_intent_client_secret,
        checkoutPaymentId,
        checkoutPayment: Boolean(checkoutPayment),
        isApplyCheckoutPayment: Boolean(isApplyCheckoutPayment),
        isGoogleCheckoutPayment: Boolean(isGoogleCheckoutPayment),
        isCardCheckoutPayment: Boolean(isCardCheckoutPayment),
        payPalPayment: Boolean(payPalPayment),
        payPalPaymentId,
        payPalCkoSessionId,
      };
    },
  },
  {
    path: '/',
    name: 'fallback',
    beforeEnter() {
      window.location.href = 'https://www.easytip.net';
    },
  },
];
