<template>
  <div class="location-card">
    <div class="location-card__menu-preview">
      <template v-if="previewMenuLink">
        <img :src="previewMenuLink" class="location-card__menu-preview_back-left" />
        <img :src="previewMenuLink" class="location-card__menu-preview_back-right" />
        <img :src="previewMenuLink" class="location-card__menu-preview_front" />
      </template>
    </div>
    <ui-button type="primary" class="location-card__button" @click="goToMenu">
      Online menu
    </ui-button>
    <ui-button type="primary" class="location-card__button" @click="goToTips">
      Leave a tip and review
    </ui-button>
    <ui-icon
      name="easytip-horizontal"
      width="110"
      height="30"
      class="location-card__logo"
    />
    <div class="location-card__credit-cards">
      <img src="@/assets/visa.svg" />
      <img src="@/assets/mastercard.svg" />
      <img src="@/assets/american-express.svg" />
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UiButton.vue';
import UiIcon from '@/components/UiIcon.vue';

export default {
  name: 'LocationCard',

  components: {
    UiIcon,
    UiButton,
  },

  props: {
    previewMenuLink: {
      type: String,
      default: '',
    },
  },

  methods: {
    goToMenu() {
      this.$router.push({ name: 'menu' });
    },
    goToTips() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
