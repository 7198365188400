<template>
  <div class="location-group" v-on="$listeners">
    <img
      :src="iconSrc"
      class="location-group__image"
    />
    <h3 class="location-group__name">
      <template v-if="group.name">
        {{ group.name }}
      </template>
      <span v-else class="location-group__empty">
        No name
      </span>
    </h3>
  </div>
</template>

<script>
import groupsConfig from '@/config/groups';

export default {

  props: {
    group: {
      type: Object,
      required: true,
    },
    locationType: String,
  },

  computed: {
    iconName() {
      return this.group.icon?.name;
    },
    iconPath() {
      return Object.entries(
        groupsConfig?.[this.locationType.toLowerCase()] || {},
      ).find(([key]) => key.toUpperCase() === this.iconName)?.[1];
    },
    iconSrc() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return this.iconPath ? require(`@/assets/groups/${this.iconPath}`) : this.emptySrc;
    },
    emptySrc() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require('@/assets/noimage.png');
    },
  },
};
</script>

<style lang="scss">
.location-group {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.18);
  border-radius: 12px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 15px;

  &__image {
    height: 50px;
    margin-right: 24px;;
  }

  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
  }

  &__empty {
    color: gray;
  }
}
</style>
