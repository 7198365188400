<template>
  <div class="receipt">
    <ui-checkbox
      v-model="receipted"
      @input="$emit('checked', receipted)"
      class="receipt__checkbox"
      red
      small
    >
      {{ $t('form.receipt') }}
    </ui-checkbox>
    <template v-if="receipted">
      <validation-observer v-slot="{ handleSubmit }">
        <validation-provider
          tag="div"
          rules="email|required"
          mode="passive"
          v-slot="{ errors }"
        >
          <ui-input
            v-model.trim="email"
            small
            class="receipt__email"
            placeholder="Email"
            :error="errors[0]"
          />
        </validation-provider>
        <ui-button
          small
          class="receipt__send"
          :disabled="!email || isLoading"
          :is-loading="isLoading"
          @click.prevent="handleSubmit(send)"
        >
          Send
        </ui-button>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UiCheckbox from './UiCheckbox.vue';
import UiInput from './UiInput.vue';
import UiButton from './UiButton.vue';

export default {

  name: 'UiReceipt',

  components: {
    UiCheckbox,
    UiInput,
    UiButton,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    isLoading: Boolean,
  },

  data: () => ({
    receipted: false,
    email: '',
  }),

  methods: {
    send() {
      this.$emit('send', this.email);
    },
  },
};
</script>

<style lang="scss">
.receipt {

  &__checkbox {
    margin-bottom: 24px;
  }

  &__email {
    margin-top: 24px;
  }

  &__send {
    margin: 24px auto;
    min-width: 128px;

  }
}
</style>
