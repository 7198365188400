import api from '@/api';
import factory from '../factory';

function loadFee(payload) {
  const {
    payoutId: workplacePayoutId,
    totalAmount: amount,
    apiVersion = 4,
  } = payload;

  if (apiVersion === 5) {
    return api.getCheckoutPaymentFee({
      workplacePayoutId,
      amount,
    });
  }

  return api.getLocationFee({
    workplacePayoutId,
    amount,
  });
}

export default factory({
  namespaced: true,

  state: {
    fees: [],
  },

  getters: {
    foundFee(state) {
      return (totalAmount) => state.fees
        .find((item) => item.totalAmount === totalAmount);
    },
    getFee(state, getters) {
      return (totalAmount) => getters
        .foundFee(totalAmount)?.feeAmount || null;
    },
    isLoading(state, getters) {
      return (totalAmount) => getters
        .foundFee(totalAmount)?.isLoading || false;
    },
  },

  mutations: {
    clear(state) {
      state.fees.splice(0);
    },
    setFee(state, payload) {
      const index = state.fees.findIndex((item) => item.totalAmount === payload.totalAmount);

      if (index > -1) {
        state.fees.splice(index, 1, payload);
      } else {
        state.fees.push(payload);
      }
    },
  },

  actions: {
    async requestFee(_, payload) {
      const {
        data: {
          amount: feeAmount,
        },
      } = await loadFee(payload);

      return parseFloat(feeAmount);
    },
    async calculateFee({ getters, commit, dispatch }, payload) {
      const { totalAmount, minTipsAmount } = payload;

      // Primitive validation.
      if (totalAmount < minTipsAmount) {
        return 0;
      }

      // Found completed or pending request.
      const found = getters.foundFee(totalAmount);

      if (found) {
        if (!found.isLoading) {
          return found.feeAmount;
        }

        return found.request;
      }

      // New request for comission.
      const request = dispatch('requestFee', payload);
      const item = {
        isLoading: true,
        feeAmount: 0,
        totalAmount,
        request,
      };

      commit('setFee', item);

      try {
        item.feeAmount = await request;
      } finally {
        item.isLoading = false;
        commit('setFee', item);
      }

      return item.feeAmount;
    },
  },
});
